export function normalizeSpecialCharacters(str: string) {
	return str.toLowerCase()

		// Change diacritics to normal characters
		.normalize('NFD')
		.replace(/[\u0300-\u036f]/g, '')
		.replace(/µ/g, 'u')
		.replace(/ø/g, 'o')
		.replace(/ß/g, 'ss')
		.replace(/æ/g, 'ae')
		.replace(/œ/g, 'oe')
		.replace(/ƒ/g, 'f')
		.replace(/[^a-zA-Z0-9]/g, '-')
		.replace(/[\\-]+/g, '-')
		.replace(/^[\\-]+|[\\-]+$/g, '');
}
