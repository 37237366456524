export function getArrayBatched<T>(arr: Array<T>, batchSize: number): Array<Array<T>> {
	const batchCount = Math.ceil(arr.length / batchSize),
		batches = [];

	for (let i = 0; i < batchCount; i++) {
		batches.push(arr.slice(i * batchSize, (i + 1) * batchSize));
	}

	return batches;
}

export function getFuncBatched<T extends Array<unknown>>(callback: (calls: Array<T>) => void, duration = 0): (...args: T) => Promise<void> {
	let calls: Array<{ args: T, res: AnyFunction, rej: AnyFunction }> = [],
		timeout: ReturnType<typeof setTimeout> = null;

	return (...args: T) => {
		return new Promise((res, rej) => {
			calls.push({args, res, rej});

			if (timeout === null) {
				timeout = setTimeout(async () => {
					const currCalls = calls;

					// Clear before callback incase callback calls same function
					timeout = null;
					calls = [];

					try {
						await callback(currCalls.map(c => c.args));

						currCalls.forEach(c => c.res());
					} catch (e) {
						currCalls.forEach(c => c.rej(e));
					}
				}, duration);
			}
		});

	};
}
