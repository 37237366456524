import Settings from "../domain/Settings";
import {getUserId} from "./userService";
import {getPageViewId} from "../utils/page-view-id";

export function init() {
	if (!Settings.getInstance().features.brandMetrics) {
		return;
	}

	window._brandmetrics = window._brandmetrics ?? [];

	const userId = getUserId();

	if (userId) {
		window._brandmetrics.push({
			cmd: "_adduserids",
			val: [userId]
		});
	}

	const pageViewId = getPageViewId();

	if (pageViewId) {
		window._brandmetrics.push({
			cmd: "_addmetadata",
			val: {
				name: "pageviewid",
				types: ["EXPOSURE"],
				value: pageViewId
			}
		});
	}

}
