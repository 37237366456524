import Settings from './domain/Settings';
import * as optOutModule from './modules/optOutModule';
import prebid from './prebid';
import prebidService from './prebid/prebidService';
import * as adManager from './services/adManager';
import * as brandMetrics from './services/brandMetrics';
import breakpoints from './services/breakpoints';
import closeableAdManager from './services/closeableAdManager';
import * as conditionalService from './services/conditionalService';
import * as cxenseService from './services/cxenseService';
import * as experimentService from './services/experimentService';
import * as heavyAdTrackingService from './services/heavyAdTrackingService';
import lazyLoadService from './services/lazyLoadService';
import './services/messaging';
import * as nativeTemplateService from './services/nativeTemplatesService';
import * as performanceService from './services/performanceService';
import * as privacyService from './services/privacyService';
import * as refreshManager from './services/refreshManager';
import sizeMappings from './services/sizeMappings';
import * as slotLoader from './services/slotLoader';
import * as slotManager from './services/slotManager';
import * as targeting from './services/targeting';
import * as userService from './services/userService';
import cmd from './utils/cmd';
import * as debugUtil from './utils/debug';
import * as hooks from './utils/hooks';
import * as perf from './utils/performance';
import pubsub from './utils/pubsub';
import {loadResource} from './utils/resource-util';

if (window.advert && typeof window.advert.init === 'function') {
	throw new Error('[ADVERT] web advertising script already loaded');
}

window.advert = window.advert ?? {} as Advert;

let latestInitConfig: InitConfig = {},
	loadSlotCalls: Array<Parameters<Advert['loadSlot']>> = [],
	loadSlotHandler: Advert['loadSlot'] = slotLoader.loadSlot;

async function init(config: InitConfig) {
	_disableLoadSlot();

	latestInitConfig = config;
	loadSlotCalls = [];

	config.resources?.filter(r => !r.consent).forEach(r => loadResource(r));

	experimentService.init(config.experiments);

	_loadSettings(config);

	_coreInit();

	privacyService.init();

	await _configInit();

	// Reload on breakpoint change, but clean up old listeners too
	pubsub.unsubscribe('breakpoint.changed', _reload);
	pubsub.subscribe('breakpoint.changed', _reload);

	return new Promise<void>((res) => {
		privacyService.onConsentReady(async () => {
			config.resources?.filter(r => !!r.consent).forEach(r => loadResource(r));

			if (privacyService.hasXandrConsent() || Settings.getInstance().features?.xandrSimpleAds) {
				if (privacyService.hasPrebidConsent()) {
					await _prebidInit(config.prebid);
				}

				cxenseService.init();
				await _xandrInit();

				_enableLoadSlot(slotLoader.loadSlot);
			} else {
				optOutModule.init();
				_enableLoadSlot(optOutModule.loadOptOutTag);
			}

			res();
		});
	});
}

function _loadSettings(config: InitConfig) {
	// Initiate settings with base values because conditions can use it
	new Settings(config.xandr, config.debugInfo);

	const configWithConditionals = conditionalService.applyConditionalConfiguration(config.xandr);

	// Now load the actual settings
	new Settings(configWithConditionals, config.debugInfo);
}

function _coreInit() {
	perf.mark('core - initialisation - start');

	hooks.init();
	window.advert.addHook = hooks.add;
	window.advert.removeHook = hooks.remove;

	perf.mark('core - initialisation - end');
}

async function _prebidInit(config: InitConfig['prebid']) {
	await prebid.init(config);
}

async function _configInit() {
	perf.mark('config - initialisation - start');

	performanceService.init();
	heavyAdTrackingService.init();
	userService.init();
	nativeTemplateService.init();
	brandMetrics.init();
	lazyLoadService.init();
	breakpoints.init();
	sizeMappings.init(); // Depends on breakpoints
	refreshManager.init();
	await slotManager.init(); // Depends on breakpoints & sizemappings

	await pubsub.publish('settings.changed');

	perf.mark('config - initialisation - end');
}

async function _xandrInit() {
	perf.mark('xandr - initialisation - start');

	await adManager.init(); // Depends on breakpoints
	await slotLoader.init(); // Depends on admanager
	closeableAdManager.init();

	await pubsub.publish('settings.changed');

	perf.mark('xandr - initialisation - end');
}

async function _reload() {
	const prevloadSlotCalls = loadSlotCalls;

	await init({
		...latestInitConfig,
		xandr: {
			...latestInitConfig.xandr,
			targeting: {
				...latestInitConfig.xandr?.targeting,
				refresh: 'breakpoint_changed'
			}
		}
	});

	prevloadSlotCalls.forEach((args) => loadSlot(...args));
}

function _disableLoadSlot() {
	cmd.reject('loaded');
}

function _enableLoadSlot(handler: Advert['loadSlot']) {
	loadSlotHandler = handler;
	cmd.accept('loaded');
}

function loadSlot(...args: Parameters<Advert['loadSlot']>) {
	loadSlotCalls.push(args);

	return loadSlotHandler(...args);
}

const initialCmds = window.advert?.cmd ?? [];

Object.assign(window.advert, {
	init,
	pubsub,
	cmd,
	'getSlot': slotManager.getSlot,
	'getSlots': slotManager.getSlots,
	'loadSlot': loadSlot,
	'defineSlots': slotLoader.defineSlots,
	'refreshSlots': slotLoader.refreshSlots,
	'getCurrentBreakpoint': breakpoints.getCurrentBreakpoint,
	'getSizeMapping': sizeMappings.getSizeMapping,
	'getSizesFromSizeMapForBreakpoint': sizeMappings.getSizesFromSizeMapForBreakpoint,
	'getBidResults': prebidService.getBidResults,
	'getPageTargeting': targeting.getPageTargeting,
	'getSlotTargeting': targeting.getSlotTargeting,
	'getSettings': () => Settings.getInstance(),
	'updatePageTargeting': (pageTargeting: Targeting) => {
		targeting.setPageTargeting(pageTargeting);
		adManager.updatePageTargeting();
	},
	'getSlotNameFromMapping': slotManager.getSlotNameFromMapping,
	'prebid': {
		'getSettings': prebid.getSettings
	},
	'getActiveExperiments': experimentService.getActiveExperiments,
	'loadDebug': debugUtil.load
});

cmd.setup(initialCmds, ['ready']);

perf.mark('core - ready');
