import {getDSASettings} from "../../utils/dsa";

type ORTB2ModuleConfig = {
	ortb2?: {
		regs?: {
			ext?: Record<string, unknown>
		}
	}
};

export function extendDSAModuleConfig(moduleConfig: ORTB2ModuleConfig): void {
	const settings = getDSASettings();

	if (!settings)
		return;

	moduleConfig.ortb2 = {
		...moduleConfig.ortb2,
		regs: {
			...moduleConfig.ortb2?.regs,
			ext: {
				...moduleConfig.ortb2?.regs?.ext,
				dsa: settings
			}
		}
	};
}
