/**
 * debounce utility
 */
function debounce(func, wait) {
	let timeout;

	return function executedFunction() {
		const args = arguments;

		clearTimeout(timeout);
		timeout = setTimeout(() => {
			timeout = null;
			func.apply(this, args);
		}, wait);
	};
}

export default debounce;
