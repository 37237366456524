import Settings from '../domain/Settings';
import {mark} from '../utils/performance';

type PerformanceMark = {
	name: string,
	entryType: string
	duration: number
	startTime: number
}

let profile: string;

const BASE_PREFIX = 'advert - ',
	BASELINE_MARK = 'performance - base',
	MARKS_TO_COLLECT = [
		'core - ready',
		'privacy - start',
		'privacy - api available',
		'privacy - tcData',
		'xandr - apntag.cmd wait - start',
		'xandr - apntag.cmd wait - end',
		'xandr - slotmanager.extendSlots - start',
		'xandr - slotmanager.extendSlots - end',
		'prebid - requestBids - start',
		'prebid - requestBids - end',
		'user - dmoi - start',
		'user - dmoi - end',
		'user - dmoi - timeout',
	];

export function init() {
	mark(BASELINE_MARK);

	const settings = Settings.getInstance();

	if (settings.features?.performanceMonitoring && _isInSampleSize(settings.performanceMonitoring.sampleSize)) {
		document.removeEventListener('visibilitychange', _onVisibilityChanged);


		profile = settings.debugInfo?.profile;
		document.addEventListener('visibilitychange', _onVisibilityChanged);
	}
}

function _isInSampleSize(size: number): boolean {
	return Math.random() <= size;
}

function _onVisibilityChanged() {
	if (document.visibilityState === 'hidden') {
		_sendLogs();
	}
}

function _sendLogs() {
	const data = {
		'profile': profile ?? window.location.hostname,
		'target': 'web',
		'measurements': _getPerformanceLogs()
	};

	navigator.sendBeacon(
		"https://performance-metrics.target.dpgmedia.cloud/ingest",
		new Blob([JSON.stringify(data)], {type: 'charset=UTF-8'})
	);
}

export function _getPerformanceLogs(): Array<[string, number]> {
	const logsToInclude = MARKS_TO_COLLECT.concat(Settings.getInstance().performanceMonitoring.additionalLogs ?? []),
		allLogs = _getAllPerformanceLogs();
	let baseLine = 0;

	return allLogs
		.reduce((arr: Array<[string, number]>, log) => {
			const type = log.name.slice(BASE_PREFIX.length);

			if (type === BASELINE_MARK) {
				baseLine = log.startTime;
			}

			if (logsToInclude.includes(type)) {
				arr.push([log.name, log.startTime - baseLine]);
			}

			return arr;
		}, []);
}

function _getAllPerformanceLogs(): Array<PerformanceMark> {
	return performance.getEntriesByType('mark')
		.filter((entry: PerformanceMark) => entry.name.indexOf(BASE_PREFIX) === 0)
		// Sort just in case someone manually inserted a custom time
		.sort((a, b) => a.startTime <= b.startTime ? -1 : 1);
}

