// Because IE doesn't have node.isConnected…
export function isConnected(node) {
	if (!node) {
		return false;
	}

	if (typeof node.isConnected !== 'undefined') {
		return node.isConnected;
	}

	let el = node;

	while (el) {
		if (el === document) {
			return true;
		}

		el = el.parentNode;
	}

	return false;
}
