import {getCurrentBreakpoint} from '../services/breakpoints';

export function getMediaTypes(config, slot) {
	const mediaTypes = {},
		slotConfig = config.slots[slot.name],
		banner = _getBanner(slotConfig);

	if (banner) {
		mediaTypes.banner = banner;
	}

	if (slotConfig.native) {
		mediaTypes.native = slotConfig.native;
	}

	return mediaTypes;
}

function _getBanner(slotConfig) {
	const sizeCfg = slotConfig.sizes;
	let sizes;

	if (Array.isArray(sizeCfg)) {
		sizes = sizeCfg;
	} else {
		sizes = sizeCfg?.[getCurrentBreakpoint()];
	}

	if (!Array.isArray(sizes) || sizes.length === 0) {
		return null;
	}

	return {
		sizes
	};
}
