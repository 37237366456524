import Settings from '../domain/Settings';
import Slot from '../domain/Slot';
import * as perf from '../utils/performance';
import breakpoints from './breakpoints';
import {hasUserProfilingConsent} from './privacyService';
import sizeMappings from './sizeMappings';
import * as targeting from './targeting';
import {getRandomizedUserId, getUserId} from './userService';
import {getDSASettings} from "../utils/dsa";

/* Prep apntag */
window.apntag = window.apntag || {} as ApnTag;
window.apntag.anq = window.apntag.anq || [];

let eventsHooked = false;

const queueReady = new Promise<void>((resolve) => {
	window.apntag.anq.push(() => {
		resolve();
	});
});

function _hookEvents() {
	if (eventsHooked) {
		return;
	}

	window.apntag.onEvent('adError', (error, adObj) => {
		console.error('[ADVERT] Something went wrong in an ad creative for the ad ', adObj, ' | Error: ', error);
	});
	eventsHooked = true;
}

export async function init() {
	perf.mark('xandr - apntag.init - start');

	perf.mark('xandr - apntag.cmd wait - start');
	await queueReady;
	perf.mark('xandr - apntag.cmd wait - end');

	window.apntag.clearRequest();

	window.apntag.setPageOpts(_getPageOptionsFromSettings());
	setUserPageOptions();
	_hookEvents();

	perf.mark('xandr - apntag.init - end');
}

export function defineSlot(slot: Slot) {
	window.apntag.defineTag(_getTagValuesFromSlot(slot));
}

function _formatSizeMappingForXandr(sizeMap: Record<string, Array<[number, number] | 'fluid'>>) {
	const sizeMaps = Object.entries(breakpoints.getBreakpoints()).map(([bpName, bpBegin]) => ({
		'minWidth': bpBegin,
		'sizes': (sizeMap[bpName] || []).filter((s) => s !== 'fluid')
	}));

	if (sizeMap.customBreakpointMapping) {
		return [...sizeMaps, ...sizeMap.customBreakpointMapping];
	}

	return sizeMaps;
}

export function loadSlots(slots: Slot[] = []) {
	window.apntag.loadTags(slots.map((s) => s.domId));
}

export function loadSlotsWithLimitedTargeting(slots: Slot[] = [], keywordsToKeep: string[] = []) {
	if (slots.length === 0) {
		return;
	}

	const {
			user,
			keywords: originalKeywords
		} = window.apntag.requests,
		keywords = Object.fromEntries(Object.entries(originalKeywords).filter(([key]) => keywordsToKeep.includes(key)));

	// Clear properties
	window.apntag.setPageOpts({
		keywords,
		user: {}
	});

	window.apntag.loadTags(slots.map((s) => s.domId));

	// Reset, breaks because of consent
	window.apntag.setPageOpts({
		keywords: originalKeywords,
		user
	});
}

export function renderSlot(slot: Slot) {
	window.apntag.modifyTag(slot.domId, _getTagValuesFromSlot(slot));
	window.apntag.showTag(slot.domId);
}

export function prepareRefreshingSlots(slots: Slot[] = []) {
	slots.forEach((slot) => {
		const tag = _getTagValuesFromSlot(slot);

		if (slot.refreshSettings?.fixedHeight) {
			tag.sizeMapping = tag.sizeMapping.map((sizeMap: SizeMap) => {
				sizeMap.sizes = sizeMap.sizes?.filter((size: Size) => size[1] === slot.adResponse?.height as number);

				return sizeMap;
			}) as SizeMap[];
		}

		window.apntag.modifyTag(slot.domId, tag);
	});
}

export function refreshSlots(slots: Slot[] = []) {
	window.apntag.refresh(slots.map((s) => s.domId));
}

function _getPageOptionsFromSettings() {
	const {
		memberId,
		enableSafeFrame,
		disablePSA,
		auctionTimeout
	} = Settings.getInstance();

	return {
		'member': memberId,
		auctionTimeout,
		'enableSafeFrame': enableSafeFrame,
		'enableMediationEvents': true,
		'disablePsa': disablePSA,
		'keywords': targeting.getPageTargeting(),
		'dsa': getDSASettings()
	};
}

export function setUserPageOptions(userIds: AnyObject[] = []) {
	if (!hasUserProfilingConsent()) {
		return;
	}

	const userPageOption: AnyObject = {},
		settings = Settings.getInstance(),
		userId = getUserId(),
		extUid = settings.user ?? userId;

	if (extUid) {
		userPageOption.externalUid = extUid;
	}

	if (settings.features?.dmoi) {
		const dmoiSource = settings.dmoi?.source;

		if (dmoiSource && userId) {
			userIds.push({
				'source': dmoiSource,
				'id': userId,
				'type': 'DMOI'
			});
		}

		const rdmoiSource = settings.dmoi?.rdmoiSource,
			rUid = getRandomizedUserId();

		if (rdmoiSource && rUid) {
			userIds.push({
				'source': rdmoiSource,
				'id': rUid,
				'type': 'RDMOI'
			});
		}
	}

	if (userIds.length > 0) {
		userPageOption.userIds = userIds;
	}

	if (Object.keys(userPageOption).length > 0) {
		window.apntag.setPageOpts({'user': userPageOption});
	}
}

export function updatePageTargeting() {
	window.apntag.setPageOpts({
		'keywords': targeting.getPageTargeting()
	});
}

function _getTagValuesFromSlot(slot: Slot): Tag {
	const placement = typeof slot.placement === 'object' ? slot.placement[breakpoints.getCurrentBreakpoint()] : slot.placement,
		placementKey = typeof placement === 'number' ? 'tagId' : 'invCode',
		tag: Tag = {
			'targetId': slot.domId,
			[placementKey]: placement,
			'sizeMapping': _formatSizeMappingForXandr(sizeMappings.getSizeMapping(slot.sizeMapping)),
			'keywords': targeting.getSlotTargeting(slot)
		} as Tag;

	if (slot.forceCreativeId) {
		tag.forceCreativeId = slot.forceCreativeId;
	}

	if (slot.memberId) {
		tag.member = slot.memberId;
	}

	return tag;
}

export function resizeSlot(slot: Slot, newSize: [number, number]) {
	window.apntag.resizeAd(slot.domId, newSize);
}

export function addRenderFinishListener(slot: Slot, callback: AnyFunction) {
	window.apntag.onEvent('adLoaded', slot.domId, callback);
}

export function addAvailableListeners(slot: Slot, callback: AnyFunction) {
	window.apntag.onEvent('adAvailable', slot.domId, (adObj: AnyObject) => callback({
		'nobid': false,
		...adObj
	}));

	// AdObj can be missing with mediation, so we at least provide for isEmpty
	window.apntag.onEvent('adNoBid', slot.domId, (adObj: AnyObject) => callback({
		'nobid': true,
		...adObj ?? {}
	}));
}

export function addRequestedListeners(slot: Slot, successCallback: AnyFunction, errorCallback: AnyFunction) {
	window.apntag.onEvent('adRequested', slot.domId, successCallback);
	window.apntag.onEvent('adBadRequest', slot.domId, (...params) => errorCallback('adBadRequest', ...params));
	window.apntag.onEvent('adRequestFailure', slot.domId, (...params) => errorCallback('adRequestFailure', ...params));
}

export function removeRequestedListeners(slot: Slot) {
	window.apntag.offEvent('adRequested', slot.domId);
	window.apntag.offEvent('adBadRequest', slot.domId);
	window.apntag.offEvent('adRequestFailure', slot.domId);
}

export function addMediationListeners(slot: Slot, callback: AnyFunction) {
	window.apntag.onEvent('adLoadedMediated', slot.domId, () => callback(false));
	window.apntag.onEvent('adNoBidMediated', slot.domId, () => callback(true));
}

export function removeRenderFinishListener(slot: Slot) {
	window.apntag.offEvent('adLoaded', slot.domId);
}

export function removeAvailableListeners(slot: Slot) {
	window.apntag.offEvent('adAvailable', slot.domId);
	window.apntag.offEvent('adNoBid', slot.domId);
}

export function removeMediationListeners(slot: Slot) {
	window.apntag.offEvent('adLoadedMediated', slot.domId);
	window.apntag.offEvent('adNoBidMediated', slot.domId);
}
