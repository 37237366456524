import breakpoints from '../services/breakpoints';
import screenUtil from '../utils/screen';

export function getValueFromThreshold(threshold) {
	let currentThreshold = threshold;

	if (typeof threshold === 'object' && threshold !== null) {
		const currentBp = breakpoints.getCurrentBreakpoint();

		currentThreshold = isValidThresholdValue(threshold[currentBp]) ? threshold[currentBp] : threshold.default;
	}

	if (typeof currentThreshold === 'string') {
		return screenUtil.getScreenRatioValue(currentThreshold);
	}

	return currentThreshold;
}

export function isValidThresholdValue(thresholdValue) {
	if (typeof thresholdValue === 'string') {
		return /^[0-9]+%$/.test(thresholdValue);
	}

	if (typeof thresholdValue === 'number') {
		return !isNaN(thresholdValue) && thresholdValue >= 0;
	}

	return false;
}
