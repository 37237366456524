import {getValueForBreakpoint} from '../utils/breakpoint-util';
import pubsub from '../utils/pubsub';
import screenUtil from '../utils/screen';
import {getValueFromThreshold, isValidThresholdValue} from '../utils/threshold-util';
import Slot from "../domain/Slot";
import Settings from "../domain/Settings";

const DEFAULT_LAZYLOAD_THRESHOLD = 300;

let lazyLoadThreshold: Settings['lazyLoadThreshold'] = DEFAULT_LAZYLOAD_THRESHOLD,
	waitingForInView: Array<{ slot: Slot, resolve: AnyFunction }> = [];

pubsub.subscribe('scroll', _resolveInViewSlots);

function init() {
	lazyLoadThreshold = Settings.getInstance().lazyLoadThreshold;
	waitingForInView = [];
}

function waitForSlot(slot: Slot) {
	if (!getValueForBreakpoint(slot.lazyLoad)) {
		return Promise.resolve(slot);
	}

	return new Promise((resolve) => {
		if (_isInView(slot)) {
			resolve(slot);
		} else {
			waitingForInView.push({
				slot,
				resolve
			});
		}
	});
}

function _isInView(slot: Slot) {
	const threshold = _getThresholdForSlot(slot);

	return screenUtil.isInView(slot.node, threshold);
}

function _getThresholdForSlot(slot: Slot) {
	let thresholdValue = getValueFromThreshold(slot.lazyLoadThreshold);

	if (!isValidThresholdValue(thresholdValue)) {
		thresholdValue = getValueFromThreshold(lazyLoadThreshold);
	}

	if (!isValidThresholdValue(thresholdValue)) {
		thresholdValue = DEFAULT_LAZYLOAD_THRESHOLD;
	}

	return typeof thresholdValue === 'string' ? screenUtil.getScreenRatioValue(thresholdValue) : thresholdValue;
}

function _resolveInViewSlots() {
	waitingForInView = waitingForInView.filter(({slot, resolve}) => {
		if (_isInView(slot)) {
			resolve(slot);

			return false;
		}

		return true;
	});
}

export default {
	init,
	waitForSlot
};
