export function isObject(a: unknown): a is AnyObject {
	return typeof a === 'object' && a !== null && !Array.isArray(a);
}

export function clone<T extends Record<string, unknown>>(obj: T): T {
	return Object.fromEntries(
		Object.entries(obj).map(([key, value]) => [key, _cloneValue(value)])
	) as T;
}

function _cloneValue<V>(value: V): V {
	if (isObject(value)) {
		return clone(value);
	}

	if (Array.isArray(value)) {
		return value.map((v) => _cloneValue(v)) as V;
	}

	return value;
}
