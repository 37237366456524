export function isFirefoxBrowser() {
	// @ts-ignore
	return typeof InstallTrigger !== 'undefined';
}

export function isSafariBrowser() {
	// @ts-ignore
	return (!!window.ApplePaySetupFeature || !!window.safari) && _agentHas('Safari') && !_agentHas('Chrome') && !_agentHas('CriOS');
}

export function isEdgeBrowser() {
	return _agentHas('edg');
}

function _agentHas(keyword: string) {
	return navigator.userAgent.toLowerCase().search(keyword.toLowerCase()) > -1;
}
