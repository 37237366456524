import {isEdgeBrowser, isFirefoxBrowser, isSafariBrowser} from '../utils/browsers';
import {getCxenseProperties} from './cxenseService';
import {getGigyaId} from '../utils/gigya';
import pubsub from '../utils/pubsub';
import querystring from '../utils/querystring';
import {getSnowplowId} from '../utils/snowplow';
import breakpoints from './breakpoints';
import {getAuthKey} from './messaging';
import {getGamblingConsent, hasUserProfilingConsent, hasVendorsConsent, hasXandrConsent} from './privacyService';
import Settings from '../domain/Settings';
import {getActiveExperiments} from './experimentService';
import * as storage from '../utils/storage';
import {getPageViewId} from '../utils/page-view-id';
import Slot from '../domain/Slot';
import {isObject} from "../utils/object-util";

const NODE_ATTR_REGEX = /%%node-attr:(.+)%%/;

function _getMetaTargeting(userProfilingConsent: boolean) {
	const metaTargeting: Targeting = {
		'screen': breakpoints.getCurrentBreakpoint(),
		'authKey': getAuthKey(),
		'targeted': userProfilingConsent,
		'adops': getAdopsTargeting(),
		'thirdpartycookie': isFirefoxBrowser() || isSafariBrowser() || isEdgeBrowser() ? 0 : 1
	};

	_addLegacyExperiments(metaTargeting);
	_addExperiments(metaTargeting);
	_addPageViewId(metaTargeting);
	_addSimpleAds(metaTargeting);
	_addGamblingConsent(metaTargeting);

	if (Settings.getInstance().features.selectedRegionTargeting) {
		_addSelectedRegionTargeting(metaTargeting);
	}

	return metaTargeting;
}

function getAdopsTargeting() {
	const adopsTargeting = querystring.get('adops') ?? storage.get('adops');

	if (adopsTargeting === undefined) {
		return;
	}

	if (typeof adopsTargeting === 'string' && adopsTargeting.includes(',')) {
		return adopsTargeting?.split(',');
	}

	return adopsTargeting;
}

function _getUserTargeting(userProfilingConsent: boolean) {
	const userTargeting = {};

	if (userProfilingConsent) {
		_addSnowplow(userTargeting);
		_addGigya(userTargeting);

		if (hasVendorsConsent([412])) {
			_addCxense(userTargeting);
		}
	}

	return userTargeting;
}

function _getPageTargeting(): Targeting {
	const userProfilingConsent: boolean = hasUserProfilingConsent(),
		configTargeting: Targeting = Settings.getInstance().targeting,
		// Safe targeting also clears out empty values so they do not override config targeting
		metaTargeting = _getSafeTargeting(_getMetaTargeting(userProfilingConsent)),
		userTargeting = _getSafeTargeting(_getUserTargeting(userProfilingConsent));

	// Meta applied twice so it's not overwritten by config targeting, but still first in the key value list so they're less likely to be ignored
	return Object.assign({}, metaTargeting, configTargeting, metaTargeting, userTargeting);
}

export function setPageTargeting(targeting: Targeting, merge = false) {
	Settings.getInstance().targeting = Object.assign(merge ? Settings.getInstance().targeting : {}, targeting);

	pubsub.publish('settings.changed');
}

export function getPageTargeting() {
	return _replacePlaceholders(_getSafeTargeting(_getPageTargeting()));
}

export function getSlotTargeting(slot: Slot) {
	const slotTargeting = {
		'slot': slot.name,
		'targetid': slot.domId,
		...slot.targeting,
	} as Targeting;

	return _replacePlaceholders(_getSafeTargeting(slotTargeting, slot.node), slot);
}

function convertNodeAttributeValue(node: HTMLElement, value: string, nodeAttributeMatch: string[]): string | string[] | null {
	const attributeValue = node?.getAttribute(nodeAttributeMatch[1]) ?? '',
		newValue = value.replace(nodeAttributeMatch[0], attributeValue);

	if (newValue.length === 0) {
		return null;
	}

	if (newValue.includes(',')) {
		return newValue.split(',');
	}

	return newValue;
}

function _getSafeTargeting(targeting: Targeting, node?: HTMLElement) {
	return Object.keys(targeting).reduce((t: Targeting, key) => {
		const value = _getSafeTargetValue(targeting[key], node);

		if (typeof value !== 'undefined' && value !== null) {
			t[key] = value;
		}

		return t;
	}, {});
}

function _getSafeTargetValue(value: unknown, node?: HTMLElement): TargetValue | Array<TargetValue> {
	if (isObject(value)) {
		value = value[breakpoints.getCurrentBreakpoint()];
	}

	if (typeof value === 'string') {
		if (node) {
			const nodeAttributeMatch = value.match(NODE_ATTR_REGEX);

			if (nodeAttributeMatch) {
				return convertNodeAttributeValue(node, value, nodeAttributeMatch);
			}
		}

		return value;
	}

	if (typeof value === 'boolean' || typeof value === 'number') {
		return value.toString();
	}

	if (Array.isArray(value)) {
		return value.map((val) => {
			if (Array.isArray(val)) {
				return null;
			}

			return _getSafeTargetValue(val, node);
		}).filter((val) => val !== null) as Array<TargetValue>;
	}

	return null;
}

function _replacePlaceholders(targeting: Targeting, slot?: Slot) {
	Object.keys(targeting).forEach((field) => {
		if (typeof targeting[field] === 'string') {
			targeting[field] = (targeting[field] as string).replace('%screenSize%', breakpoints.getCurrentBreakpoint());

			if (slot) {
				targeting[field] = (targeting[field] as string).replace('%slotName%', slot.name);
			}
		}
	});

	return targeting;
}

function _addCxense(targeting: Targeting) {
	if (!Settings.getInstance().features.cxense) {
		return;
	}

	const cxenseProperties = getCxenseProperties();

	if (cxenseProperties) {
		targeting.cxid = cxenseProperties.id;
	}
}

function _addSnowplow(targeting: Targeting) {
	if (!Settings.getInstance().features.snowplow) {
		return;
	}

	targeting.spid = getSnowplowId();
}

function _addGigya(targeting: Targeting) {
	const gigyaFeature = Settings.getInstance().features.gigya;

	if (!gigyaFeature) {
		return;
	}

	if (typeof gigyaFeature === 'string') {
		targeting.gigyaid = getGigyaId(gigyaFeature);
	} else {
		targeting.gigyaid = getGigyaId();
	}
}

function _addExperiments(targeting: Targeting) {
	const experiments = getActiveExperiments();

	if (experiments.length > 0) {
		targeting.experiments = experiments.map((experiment) => `${experiment.name}_${experiment.group}`);
	}
}

function _addLegacyExperiments(targeting: Targeting) {
	if (!Settings.getInstance().features.experiments) {
		return;
	}

	targeting.exp = storage.getFromCookie('exp');
}

function _addPageViewId(targeting: Targeting) {
	const pageViewId = getPageViewId();

	if (pageViewId) {
		targeting.pageviewid = pageViewId;
	}
}

function _addSimpleAds(targeting: Targeting) {
	if (!Settings.getInstance().features.xandrSimpleAds) {
		return;
	}

	targeting.simpleads = !hasXandrConsent();
}

function _addGamblingConsent(targeting: Targeting) {
	if (!Settings.getInstance().features.gamblingConsent) {
		return;
	}

	targeting.gambling = getGamblingConsent();

	_addAgeConsent(targeting);
}

function _addAgeConsent(targeting: Targeting) {
	const ageConsent = Settings.getInstance().gamblingConsent?.ageConsent;

	if (!ageConsent) {
		return;
	}

	targeting[ageConsent.targeting] = storage.getFromCookie(ageConsent.cookie) ?? ageConsent.defaultValue;
}

function _addSelectedRegionTargeting(targeting: Targeting) {
	const selectedRegion = storage.getFromCookie('selectedRegio');

	if (selectedRegion) {
		targeting.selectedRegion = selectedRegion;
	}
}
