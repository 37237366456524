import util from './util';
import Slot from "../../domain/Slot";

const TARGETING_NAME_REMAPPINGS: Record<string, string> = {
	iabCategories: 'ia',
	iabTopics: 'tp',
	cat: 'ct'
};

export default (config: PrebidConfig, slot: Slot, slotBidderCfg: AnyObject) => {
	const {
		targetingFields = [],
		data = {},
		...params
	} = util.getParamsFromConfig(config, slotBidderCfg);

	Object.assign(data, util.getTargeting(slot, targetingFields));

	if (Object.keys(data).length > 0) {
		params.data = Object.fromEntries(
			Object.entries(data).map(([key, value]) => [TARGETING_NAME_REMAPPINGS[key] ?? key, value])
		);
	}

	return {
		'bidder': slotBidderCfg.bidder ?? 'adhese',
		params
	};
};
