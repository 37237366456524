import {getPageTargeting} from '../../services/targeting';
import {isObject} from "../../utils/object-util";

export type PriceFloorConfig = {
	data?: {
		schema?: {
			fields: string[]
		}
	}
	additionalSchemaFields?: Record<string, (bidResponse?: AnyObject) => string>
};

export function extendPriceFloorConfig(moduleConfig: {floors?: PriceFloorConfig}) {
	if (isObject(moduleConfig.floors)) {
		moduleConfig.floors.additionalSchemaFields = _getAdditionalSchemaFieldGetters(moduleConfig.floors as PriceFloorConfig);
	}
}

// Defaults schema fields are gptSlot, adUnitCode, mediaType, size
function _getAdditionalSchemaFieldGetters(floorConfig: PriceFloorConfig) {
	const targetingFields = floorConfig.data?.schema?.fields?.filter((f) => f.startsWith('targeting:')) ?? [],
		targetingGetters = _getTargetingGetters(targetingFields);

	return {
		'bidder': _getFromBidResponse('bidder'),
		'width': _getFromBidResponse('width'),
		'height': _getFromBidResponse('height'),
		'currency': _getFromBidResponse('currency'),
		'creativeId': _getFromBidResponse('creativeId'),
		...targetingGetters
	};
}

function _getFromBidResponse(field: string) {
	return (bidResponse: AnyObject) => bidResponse[field] ? `${bidResponse[field]}` : '*';
}

function _getTargetingGetters(fields: string[]) {
	return fields.reduce((agg: Record<string, () => string>, field) => {
		const targetingKey = field.slice('targeting:'.length);

		agg[field] = () => {
			const pageTargeting = getPageTargeting();

			return `${pageTargeting[targetingKey] ?? '*'}`;
		};

		return agg;
	}, {});
}
