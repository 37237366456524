import util from './util';

export default (config, slot, slotBidderCfg) => {
	const {
			targetingFields = [],
			...params
		} = util.getParamsFromConfig(config, slotBidderCfg),
		targeting = util.getTargeting(slot, targetingFields);

	params.keyValues = Object.keys(targeting).reduce((t, key) => {
		t[key] = Array.isArray(targeting[key]) ? targeting[key] : [targeting[key]];

		return t;
	}, {});

	return {
		'bidder': slotBidderCfg.bidder || 'improvedigital',
		params
	};
};
