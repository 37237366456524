import * as storage from './storage';
import Settings from '../domain/Settings';

export type GigyaModes = 'premium' | 'freemium' | 'dpg_mm' | 'nunl';

export function getGigyaId(type = Settings.getInstance().gigya?.type): string | false {
	switch (type) {
		case 'dpg_mm': {
			return _sanomaModule();
		}

		case 'nunl': {
			return _getNuNlGigyaId();
		}

		case 'freemium': {
			return _freemium();
		}

		case 'premium': {
			return _premium();
		}

		default: {
			return false;
		}
	}
}

function _sanomaModule() {
	const gigyaid = storage.getFromCookie('SA_UID');

	if (gigyaid) {
		return decodeURIComponent(gigyaid);
	}

	return false;
}

function _getNuNlGigyaId(): string | false {
	// Provided by Nu.nl team
	// We could consider importing an actual small JWT parser
	function parseJwt(token: string) {
		const base64 = token.split('.')[1].replace(/-/g, '+').replace(/_/g, '/'),
			jsonPayload = decodeURIComponent(
				window.atob(base64)
					.split('')
					.map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
					.join('')
			);

		return JSON.parse(jsonPayload);
	}

	const cookie = storage.getFromCookie('dpg-component-at');

	return cookie ? parseJwt(cookie).sub : false;
}

function _freemium() {
	// @ts-ignore
	const dl = window.dataLayer?.find(l => l.user?.account_id);

	if (dl) {
		// @ts-ignore
		return dl.user.account_id;
	}

	return false;
}

function _premium() {
	const dl = window.dataLayer?.find(l => l['gigya-id']);

	if (dl) {
		return dl['gigya-id'] as string | false | null;
	}

	return false;
}
