import {isObject} from './object-util';

export function mergeDeep(...sources: AnyObject[]): AnyObject {
	const result: Record<string, unknown> = {};

	if (!sources.every(isObject)) {
		throw new Error('Can not deep merge non-objects');
	}

	sources.forEach((source) => {
		Object.keys(source).forEach(key => {
			const value = source[key];

			if (isObject(value)) {
				if (Object.keys(value).length === 0) {
					// New object, so no reference linking is happening
					result[key] = {};

					return;
				}

				if (!isObject(result[key])) {
					result[key] = JSON.parse(JSON.stringify(value));

					return;
				}

				result[key] = mergeDeep(result[key] as AnyObject, value);

				return;
			}

			result[key] = value;
		});
	});

	return result;
}
