import {applyConditionalConfiguration} from '../services/conditionalService';
import {mergeDeep} from '../utils/merge-util';
import * as perf from '../utils/performance';
import pubsub from '../utils/pubsub';
import prebidService from './prebidService';

let config,
	maxIterations;

pubsub.subscribe('extendSlots', _extendSlots);

async function init(cfg) {
	perf.mark('prebid - initialisation - start');
	config = await _readConfig(cfg);

	if (config && Object.keys(config).length !== 0) {
		config = mergeDeep(config, cfg);
	}

	if (!config || Object.keys(config).length === 0) {
		config = null;
	}

	if (config) {
		config = applyConditionalConfiguration(config);
	}

	perf.mark('prebid - initialisation - end');
}

function _readConfig(cfg) {
	if (typeof cfg?.caching?.getConfig === 'function') {
		return new Promise((resolve) => {
			_waitForHeaderBiddingConfig(cfg.caching, 1, resolve);
		});
	}

	return cfg;
}

async function _waitForHeaderBiddingConfig(cachingConfig, iteration, resolve) {
	const cfg = await cachingConfig.getConfig();

	if (cfg) {
		resolve(cfg);

		return;
	}

	if (iteration >= cachingConfig.maxIterations) {
		console.warn(`[ADVERT] Header bidding is skipped after trying fetching ${maxIterations} times.`);

		resolve(null);

		return;
	}

	setTimeout(() => _waitForHeaderBiddingConfig(cachingConfig, ++iteration, resolve), iteration * 100);
}

function _extendSlots(slots) {
	if (config) {
		return prebidService.getBids(config, slots).catch((err) => {
			console.error('[ADVERT] Something went wrong getting prebid bids.', err);
		});
	}
}

function getSettings() {
	return config;
}

export default {
	init,
	getSettings
};
