export const COMPARISON = {
	'BEFORE': -1,
	'EQUAL': 0,
	'AFTER': 1
};

type SemVer = {
	major: number,
	minor: number,
	patch: number,
	build?: string,
	label?: string,
};

export function getFromString(version: unknown): SemVer | null {
	if (!version || typeof version !== 'string') {
		return null;
	}

	// <digits><dot or slash><digits><dot or slash><digits><-label>?<+build>?
	// E.g. 3.4.5-pre-release+build123. More info: https://regex101.com/r/DqSVEX/1
	const versionMatch = version.match(/^([0-9]+)(?:\.([0-9]+)(?:\.([0-9]+))?)?(?:-([^+]*))?(?:\+(.*))?$/);

	if (!versionMatch) {
		return null;
	}

	const versionParts: SemVer = {
		'major': parseInt(versionMatch[1], 10),
		'minor': versionMatch[2] ? parseInt(versionMatch[2], 10) : 0,
		'patch': versionMatch[3] ? parseInt(versionMatch[3], 10) : 0
	};

	if (versionMatch[4]) {
		versionParts['label'] = versionMatch[4];
	}

	if (versionMatch[5]) {
		versionParts['build'] = versionMatch[5];
	}

	return versionParts;
}

// Only works with minor/patch up to 10e6. Should be good enough…
export function compare(a: SemVer, b: SemVer): typeof COMPARISON[keyof typeof COMPARISON] {
	const numA = _asNum(a),
		numB = _asNum(b);

	if (numA < numB) {
		return COMPARISON.AFTER;
	}

	if (numA > numB) {
		return COMPARISON.BEFORE;
	}

	return COMPARISON.EQUAL;
}

function _asNum(semver: SemVer): number {
	return semver.major * 10e12 + semver.minor * 10e6 + semver.patch;
}
