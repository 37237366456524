import * as performance from '../utils/performance';
import Settings from '../domain/Settings';

const SRC_ATTRIBUTE = 'data-advert-src',
	FALLBACK_SRC_ATTRIBUTE = 'data-advert-fallback-src',
	VENDOR_ATTRIBUTE = 'data-advert-vendors',
	PURPOSE_ATTRIBUTE = 'data-advert-purposes';

let enabled = false,
	tcfConsentListener = false,
	consents: Privacy.Consents = null,
	onConsentCallbacks: Array<(consents?: Privacy.Consents) => void> = [],
	gamblingConsent: boolean = false;

export function init(): void {
	enabled = !!Settings.getInstance().features.privacy;
	onConsentCallbacks = [];
	consents = null;

	if (Settings.getInstance().features.gamblingConsent) {
		_getGamblingConsent();
	}

	if (enabled) {
		performance.mark('privacy - start');
		_registerListener();
	}
}

function _getGamblingConsent() {
	const pWindow = (window as Privacy.Window);

	pWindow._privacy = pWindow._privacy || [];
	pWindow._privacy.push([
		'online_gambling_ads',
		() => gamblingConsent = true,
		() => gamblingConsent = false,
	]);
}

function _registerListener() {
	_waitForTCFAPI(() => {
		performance.mark('privacy - api available');

		if (tcfConsentListener) {
			(window as Privacy.Window).__tcfapi('removeEventListener', 2, _onTCFConsent);
		}

		(window as Privacy.Window).__tcfapi('addEventListener', 2, _onTCFConsent);
		tcfConsentListener = true;
	}, 0);
}

function _waitForTCFAPI(cb: () => void, iteration: number) {
	if (typeof (window as Privacy.Window).__tcfapi === 'function') {
		cb();

		return;
	}

	setTimeout(() => _waitForTCFAPI(cb, ++iteration), iteration * 100);
}

function _onTCFConsent(tcData: Privacy.tcData, tcDataSuccess: boolean) {
	if (tcDataSuccess && ['tcloaded', 'useractioncomplete'].indexOf(tcData.eventStatus) !== -1) {
		performance.mark('privacy - tcData');

		_updateConsent(tcData);
	}
}

function _updateConsent(tcData: Privacy.tcData) {
	consents = {
		'purposes': tcData.purpose.consents,
		'vendors': tcData.vendor.consents
	};

	_loadConsentedSources();

	onConsentCallbacks.forEach(cb => cb(consents));
}

export function onConsentReady(callback: (consents?: Privacy.Consents) => void): void {
	if (!enabled) {
		callback();

		return;
	}

	onConsentCallbacks.push(callback);

	if (consents) {
		callback(consents);
	}
}

function _loadConsentedSources() {
	document.querySelectorAll('[' + SRC_ATTRIBUTE + ']').forEach((el) => {
		const vendors = el.getAttribute(VENDOR_ATTRIBUTE)?.split(',') ?? [],
			purposes = el.getAttribute(PURPOSE_ATTRIBUTE)?.split(',') ?? [];

		// Any vendor doesn't have consent?
		if (vendors.some((v) => !consents.vendors[v]) || purposes.some((v) => !consents.purposes[v])) {
			const fallbackSrc = el.getAttribute(FALLBACK_SRC_ATTRIBUTE);

			if (fallbackSrc) {
				_loadScriptWithSrc(el, fallbackSrc);
			}

			return;
		}

		_loadScriptWithSrc(el, el.getAttribute(SRC_ATTRIBUTE));
	});
}

function _loadScriptWithSrc(el: Element, src: string) {
	el.setAttribute('src', src);
	el.removeAttribute(SRC_ATTRIBUTE);
	el.removeAttribute(VENDOR_ATTRIBUTE);
	el.removeAttribute(PURPOSE_ATTRIBUTE);
	el.removeAttribute(FALLBACK_SRC_ATTRIBUTE);
}

export function hasXandrConsent(): boolean {
	return hasPurposesConsent([1, 2, 7, 10]) && hasVendorsConsent([32]);
}

export function hasPrebidConsent(): boolean {
	return hasPurposesConsent([1, 2, 7, 10]);
}

export function hasUserProfilingConsent(): boolean {
	return hasPurposesConsent([3, 4]);
}

export function hasVendorsConsent(vendors: Array<number | string>): boolean {
	if (!enabled) {
		return true;
	}

	return vendors.every((v) => consents?.vendors[v]);
}

export function hasPurposesConsent(purposes: Array<number | string>) {
	if (!enabled) {
		return true;
	}

	return purposes.every((v) => consents?.purposes[v]);
}

export function getGamblingConsent() {
	return gamblingConsent;
}
