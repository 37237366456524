import {getArrayBatched} from '../utils/batch-util';
import * as perf from '../utils/performance';
import {getAdUnit} from './adUnits';
import {
	getBidderModuleConfigs,
	getBidderSettingsConfigs,
	getModuleConfig
} from './modules';
import {setPrebidUserIds} from './modules/userIds';

window.pbjs = window.pbjs || {};
window.pbjs.que = window.pbjs.que || [];

const DEFAULT_TIMEOUT = 8000,
	DEFAULT_FAILSAFE_BUFFER_TIMEOUT = 500;
let bidResults = [];

function _applyBidderAliases() {
	window.pbjs.aliasBidder('improvedigital', 'imprweborama');
	window.pbjs.aliasBidder('appnexus', 'gps', {gvlid: 32});
	window.pbjs.aliasBidder('appnexus', 'weborama', {gvlid: 32});
	window.pbjs.aliasBidder('appnexus', 'plista', {gvlid: 32});
	window.pbjs.aliasBidder('appnexus', 'groupm', {gvlid: 32});
}

function _applyConfigs(config) {
	window.pbjs.setConfig(getModuleConfig(config));

	getBidderModuleConfigs(config).forEach((bidderConfig) => {
		window.pbjs.setBidderConfig(bidderConfig);
	});

	window.pbjs.bidderSettings = Object.assign({}, window.pbjs.bidderSettings, getBidderSettingsConfigs(config));
}

function _getBidTimeout(config) {
	return config.timeout ?? DEFAULT_TIMEOUT;
}

function _getFailSafeTimeout(config) {
	return _getBidTimeout(config) + (config.failsafeTimeoutBuffer ?? DEFAULT_FAILSAFE_BUFFER_TIMEOUT);
}

function _waitForPrebid(config) {
	perf.mark('prebid - pbjs.que wait - start');

	return new Promise((resolve, reject) => {
		const timeout = setTimeout(() => {
			perf.mark('prebid - pbjs.que wait - fail');
			reject(new Error('Timed out waiting for Prebid.js to load'));
		}, _getFailSafeTimeout(config));

		window.pbjs.que.push(() => {
			clearTimeout(timeout);
			perf.mark('prebid - pbjs.que wait - end');
			resolve();
		});
	});
}

function _requestBids(config, units) {
	const batches = config.adUnitBatchSize ? getArrayBatched(units, config.adUnitBatchSize) : [units];

	return Promise.all(batches.map((adUnitsBatch, idx) => {
		return new Promise((resolve) => {
			perf.mark(`prebid - requestBids - batch ${idx} - start`);
			window.pbjs.requestBids({
				'adUnits': adUnitsBatch.map(([, adUnit]) => adUnit),
				'bidsBackHandler': (unitBidResponses, ...rest) => {
					bidResults.push([unitBidResponses, ...rest]);

					_cleanBids(units, unitBidResponses);

					perf.mark(`prebid - requestBids - batch ${idx} - end`);
					resolve();
				},
				'timeout': _getBidTimeout(config)
			});
		});
	}));
}

function _cleanBids(units, unitBidResponses) {
	Object.entries(unitBidResponses ?? {}).forEach(([code, bidResponse]) => {
		_applyBidResizing(units, code, bidResponse);
	});
}

function _applyBidResizing(units, code, bidResponse) {
	const slot = units.find(([, adUnit]) => adUnit.code === code)?.[0];

	if (slot) {
		bidResponse.bids?.forEach((bid) => {
			const remapSize = slot.getRemapSize([bid.width, bid.height], bid.mediaType);

			if (remapSize) {
				bid.width = remapSize[0];
				bid.height = remapSize[1];
			}
		});
	}
}

export async function getBids(config, slots) {
	await _waitForPrebid(config);
	await _getBids(config, slots);
}

async function _getBids(config, slots) {
	return new Promise((resolve, reject) => {
		perf.mark('prebid - getBids - start');

		const timeout = setTimeout(() => {
				reject(new Error('Timed out waiting for prebid bids'));
			}, _getFailSafeTimeout(config)),
			units = slots
				.filter(s => s.state.defined)
				.map((slot) => [slot, getAdUnit(config, slot)])
				.filter(([, a]) => a !== null);

		getUnits(config, units).then(() => {
			perf.mark('prebid - getBids - end');
			clearTimeout(timeout);
			resolve();
		});
	});
}

async function getUnits(config, units) {
	if (units.length === 0) {
		return;
	}

	_applyConfigs(config);
	_applyBidderAliases();
	window.pbjs.addAdUnits(units.map(([, a]) => a));

	perf.mark('prebid - requestBids - start');
	await _requestBids(config, units);
	perf.mark('prebid - requestBids - end');

	window.pbjs.setTargetingForAst(units.map(([, a]) => a.code));
	await setPrebidUserIds();
}

export function getBidResults() {
	return bidResults;
}

export default {
	getBids,
	getBidResults
};
