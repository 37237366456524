type Callback = (...args: unknown[]) => void;

function throttle<F extends Callback>(callback: F, limit: number): F {
	let wait = false,
		refire = false,
		lastArgs: unknown[];

	return function throttled(...args) {
		lastArgs = args;

		if (wait) {
			refire = true;
		} else {
			callback.apply(this, lastArgs);
			wait = true;

			setTimeout(() => {
				wait = false;

				if (refire) {
					refire = false;
					throttled.apply(this, lastArgs);
				}
			}, limit);
		}
	} as F;
}

export default throttle;
