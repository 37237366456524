import Settings from "../domain/Settings";
import {RenderContext} from "../types/nativeTemplates";

export function init(): void {
	const settings = Settings.getInstance().nativeTemplates;

	if (!settings) {
		return;
	}

	const renderConfig = JSON.parse(JSON.stringify(settings.renderConfig));

	if (settings.darkModeType === 'nunl') {
		renderConfig.darkMode = document.querySelector('html')?.getAttribute('data-theme') === 'dark';
	}

	renderConfig.onModalOpen = (renderContext: RenderContext) => {
		renderContext.renderConfig.context?.slot?.pauseRefreshing?.();
	};
	renderConfig.onModalClose = (renderContext: RenderContext) => {
		renderContext.renderConfig.context?.slot?.resumeRefreshing?.();
	};

	window.advert.nativeTemplates = window.advert.nativeTemplates || {};
	window.advert.nativeTemplates.renderConfig = {
		...window.advert.nativeTemplates.renderConfig,
		...renderConfig
	};
}
