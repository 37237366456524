const urlParameters: Record<string, string | string[]> = {};

_init(window.location.search);


function _init(str: string) {
	const params = new URLSearchParams(str.slice(1));

	for (const [key, value] of params) {
		if (urlParameters[key]) {
			urlParameters[key] = [].concat(urlParameters[key], value);
		} else {
			urlParameters[key] = value;
		}
	}
}

function getParameter(key: string): string | string[]{
	return urlParameters[key];
}

export default {
	'get': getParameter
};
