const registeredHooks: Record<string, AnyFunction> = {};

declare global {
	interface Window {
		dfp?: Record<string, AnyFunction>;
		advertHooks?: Record<string, unknown>;
	}
}

export function init() {
	Object.keys(registeredHooks).forEach((registeredHook) => {
		remove(registeredHook);
	});
}

function _createLegacyHookPassthrough(hook: string) {
	window.dfp = window.dfp || {};

	if (typeof window.dfp[hook] === 'undefined') {
		window.dfp[hook] = (...args) => {
			console.warn(`[ADVERT] Used a deprecated window.dfp hook '${hook}'. This should be updated.`);
			run(hook, ...args);
		};
	}
}

export function add(hook: string, callback: AnyFunction) {
	registeredHooks[hook] = callback;

	_createLegacyHookPassthrough(hook);
}

export function remove(hook: string) {
	delete registeredHooks[hook];

	_removeLegacyHookPassthrough(hook);
}

function _removeLegacyHookPassthrough(hook: string) {
	if (window.dfp && typeof window.dfp[hook] === 'function') {
		delete window.dfp[hook];
	}
}

export function run(hook: string, ...args: unknown[]) {
	try {
		if (typeof registeredHooks[hook] === 'function') {
			registeredHooks[hook](...args);
		}

		const advertHook = window.advertHooks?.[hook];

		if (typeof advertHook === 'function') {
			advertHook(...args);
		}
	} catch (e) {
		console.error('[ADVERT] Error running hook', hook, ...args, e);
	}
}
