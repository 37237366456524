import Settings from "../domain/Settings";

export interface DSASettings {
	dsarequired: number;
	pubrender: number;
	datatopub: number;
	transparency?: Array<{
		domain: string;
		dsaparams: number[];
	}>;
}

export function getDSASettings(): DSASettings | null {
	const dsa = Settings.getInstance().dsa;

	if (!dsa) {
		return null;
	}

	return {
		dsarequired: dsa.required,
		pubrender: dsa.pubRender,
		datatopub: dsa.dataToPub,
		transparency: dsa.transparencyList?.map(t => ({
			domain: t.domain,
			dsaparams: t.dsaParams
		}))
	};
}
