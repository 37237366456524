import {isObject} from './object-util';
import breakpoints from '../services/breakpoints';

export function getValueForBreakpoint<T>(baseValue: BreakpointOverridable<T>, allowDefault = true): T {
	if (isObject(baseValue) && !Array.isArray(baseValue)) {
		const currentBp = breakpoints.getCurrentBreakpoint();

		if (typeof baseValue[currentBp] === "undefined" && allowDefault) {
			return baseValue.default;
		}

		return baseValue[currentBp];
	}

	return baseValue as T;
}
