type ConsentManagementConfig = {
	consentManagement: {
		gdpr: Record<string, unknown>
	}
};

export function getConsentManagementConfig(config: PrebidConfig): ConsentManagementConfig | null  {
	const {
		forceDisable,
		timeout,
		...gdprConfig
	} = config.gdpr ?? {};

	if (forceDisable)
		return null;

	return {
		consentManagement: {
			gdpr: {
				cmpApi: 'iab',
				defaultGdprScope: true,
				timeout: typeof timeout === 'undefined' ? 8000 : timeout,
				...gdprConfig
			}
		}
	};
}
