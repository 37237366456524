import * as bidders from './bidderService';
import * as mediaTypes from './mediaTypes';
import Slot from "../domain/Slot";
import {applyPlaceholders} from "../utils/placeholder";
import {getPlaceholders} from "./modules";

type AdUnit = {
	code: string
	mediaTypes: { banner?: { sizes: unknown[]; }; native?: unknown; }
	bids: unknown[]
	ortb2Imp?: {
		ext: {
			gpid: string,
			data: {
				pbadslot: string
			}
		}
	}
};

export function getAdUnit(config: PrebidConfig, slot: Slot): AdUnit {
	if (typeof config.slots === 'undefined' || typeof config.slots[slot.name] === 'undefined') {
		return null;
	}

	const mt = mediaTypes.getMediaTypes(config, slot),
		bids = bidders.getBidders(config, slot);

	if (Object.keys(mt).length === 0 || bids.length === 0) {
		return null;
	}

	const adUnit: AdUnit = {
		'code': slot.domId,
		'mediaTypes': mt,
		bids,
		'ortb2Imp': {
			'ext': {
				'gpid': slot.name,
				'data': {
					'pbadslot': slot.name
				}
			}
		}
	};

	return applyPlaceholders(adUnit, getPlaceholders());
}
