import {isObject} from "./object-util";

export function applyPlaceholders<T>(variable: T, placeholders: AnyObject): T {
	if (typeof variable === 'string') {
		return _replacePlaceholders(variable, placeholders) as T;
	}

	if (Array.isArray(variable)) {
		return variable.map((s) => applyPlaceholders(s, placeholders)) as T;
	}

	if (isObject(variable)) {
		return Object.keys(variable).reduce((agg: AnyObject, key) => {
			agg[key] = applyPlaceholders(variable[key], placeholders);

			return agg;
		}, {}) as T;
	}

	return variable;
}

function _replacePlaceholders(input: string, placeholders: AnyObject): unknown {
	let str = input;

	for (const [key, value] of Object.entries(placeholders)) {
		const placeholder = `%%${key}%%`;

		if (str === placeholder) {
			return value;
		}

		str = str.replace(new RegExp(placeholder, 'g'), `${value ?? ''}`);
	}

	return str;
}
