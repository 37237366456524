import Settings from '../domain/Settings';
import {getBreakpoints} from './breakpoints';

let sizeMappings = {};

function init() {
	const settings = Settings.getInstance();

	_validateSizeMappings(settings.sizeMapping);

	sizeMappings = JSON.parse(JSON.stringify(settings.sizeMapping));
}

function _isTwoValidDimensions(size) {
	return Array.isArray(size) && size.length === 2
		&& typeof size[0] === 'number' && typeof size[1] === 'number'
		&& size[0] > 0 && size[1] > 0;
}

function _validateSizeMappings(sms) {
	const breakpointNames = Object.keys(getBreakpoints());

	Object.keys(sms).forEach((type) => {
		Object.keys(sms[type]).forEach((breakpoint) => {
			const sizeMapping = sms[type][breakpoint];

			if (breakpointNames.indexOf(breakpoint) === -1) {
				return;
			}

			if (!Array.isArray(sizeMapping)) {
				throw new Error(`Breakpoint '${breakpoint}' of size mapping '${type}' isn't an array.`);
			}

			sizeMapping.forEach((size) => {
				if (size === 'fluid') {
					return;
				}

				if (_isTwoValidDimensions(size)) {
					return;
				}

				throw new Error(`Invalid size in breakpoint '${breakpoint}' of size mapping '${type}'.`);
			});
		});
	});
}

function getSizeMapping(type) {
	return typeof sizeMappings[type] === 'undefined' ? {} : sizeMappings[type];
}

function getSizesFromSizeMapForBreakpoint(type, currentBreakpoint) {
	const sizeMapping = getSizeMapping(type),
		sortedBreakpoints = Object.entries(getBreakpoints()).sort(([, b1], [, b2]) => b1 === b2 ? 0 : b1 < b2 ? -1 : 1);
	let sizes = sizeMapping[currentBreakpoint];

	if (sizeMapping.customBreakpointMapping && sizeMapping.customBreakpointMapping.length) {
		sizeMapping.customBreakpointMapping.sort((a, b) => a.minWidth < b.minWidth ? -1 : 1)
			.forEach((sizeMap) => {
				if (window.innerWidth >= sizeMap.minWidth) {
					for (let i = sortedBreakpoints.length - 1; i >= 0; i--) {
						if (sortedBreakpoints[i][1] <= sizeMap.minWidth && sortedBreakpoints[i][0] === currentBreakpoint) {
							sizes = sizeMap.sizes;
							break;
						}
					}
				}
			});
	}

	return sizes;
}

export default {
	init,
	getSizeMapping,
	getSizesFromSizeMapForBreakpoint
};
