import debounce from './debounce';
import pubsub from './pubsub';
import throttle from './throttle';

window.addEventListener('scroll', throttle(_scroll, 250), {'passive': true});
window.addEventListener('resize', debounce(_resize, 250), {'passive': true});
window.addEventListener('orientationchange', debounce(_resize, 250), {'passive': true});
window.addEventListener('pageshow', (event) => _pageShow(event), {'passive': true});

function _scroll() {
	pubsub.publish('scroll', window.scrollY);
}

function _resize() {
	pubsub.publish('resize', getSize().width);
}

function _pageShow(event) {
	if (event?.persisted) {
		pubsub.publish('persisted');
	}
}

function getSize() {
	return {
		'height': window.innerHeight || document.documentElement.clientHeight,
		'width': window.innerWidth || document.documentElement.clientWidth
	};
}

function isInView(node, threshold = 0) {
	if (!node || !(node instanceof Element)) {
		throw new Error('Invalid argument for isInView, argument 1 is not an Element');
	}

	if (_isHidden(node)) {
		return false;
	}

	let rect = node.getBoundingClientRect(),
		screenSize = getSize();

	return rect.bottom + threshold >= 0 &&
		rect.right + threshold >= 0 &&
		rect.top - threshold <= screenSize.height &&
		rect.left - threshold <= screenSize.width;
}

function _isHidden(node) {
	return node.getClientRects().length === 0;
}

export function getScreenRatioValue(ratio) {
	const percent = parseInt(ratio.slice(0, -1), 10),
		screenHeight = getSize().height;

	if (isNaN(percent)) {
		return;
	}

	return screenHeight * percent / 100;
}

export default {
	getSize,
	isInView,
	getScreenRatioValue
};
