import {normalizeSpecialCharacters} from './string-util';
import * as semverUtil from './semver';

export type Comparer = 'eq' | 'in' | 'lt' | 'lte' | 'gt' | 'gte'
	| 'regEx' | 'sw' | 'ew' | 'bf' | 'af' | 'ltv' | 'ltev' | 'gtv' | 'gtev'
	| 'missing';

export const COMPARERS: Record<string, Comparer> = {
	'EQUALS': 'eq',
	'CONTAINS': 'in',
	'LESS_THAN': 'lt',
	'LESS_THAN_OR_EQUAL': 'lte',
	'GREATER_THAN': 'gt',
	'GREATER_THAN_OR_EQUAL': 'gte',
	'REGULAR_EXPRESION': 'regEx',
	'STARTS_WITH': 'sw',
	'ENDS_WITH': 'ew',
	'BEFORE': 'bf',
	'AFTER': 'af',
	'LESS_THAN_VERSION': 'ltv',
	'LESS_THAN_OR_EQUAL_VERSION': 'ltev',
	'GREATER_THAN_VERSION': 'gtv',
	'GREATER_THAN_OR_EQUAL_VERSION': 'gtev',
	'MISSING': 'missing'
};

export function isConditionActive(condition: Condition, fieldValue: unknown): boolean {
	const {
		comparer = COMPARERS.EQUALS,
		value,
		normalized = false
	} = condition;

	if (comparer.indexOf('!') === 0) {
		return !isConditionActive({
			...condition,
			'comparer': comparer.slice(1) as Comparer
		}, fieldValue);
	}

	if (normalized) {
		if (typeof fieldValue === 'string') {
			fieldValue = normalizeSpecialCharacters(fieldValue);
		} else if (Array.isArray(fieldValue)) {
			fieldValue = fieldValue.map(normalizeSpecialCharacters);
		}
	}

	if (typeof fieldValue === 'undefined' || fieldValue === null) {
		return comparer === COMPARERS.MISSING;
	}

	if (comparer === COMPARERS.MISSING) {
		return false;
	}

	if (comparer === COMPARERS.BEFORE || comparer === COMPARERS.AFTER) {
		return _dateCompare(comparer, fieldValue, value);
	}

	if (comparer === COMPARERS.EQUALS) {
		return _equals(fieldValue, value);
	}

	if (comparer === COMPARERS.CONTAINS) {
		return _contains(fieldValue, value);
	}

	if (comparer === COMPARERS.LESS_THAN || comparer === COMPARERS.LESS_THAN_OR_EQUAL
		|| comparer === COMPARERS.GREATER_THAN || comparer === COMPARERS.GREATER_THAN_OR_EQUAL) {
		return _quantitativeCompare(comparer, fieldValue, value);
	}

	if (comparer === COMPARERS.LESS_THAN_VERSION || comparer === COMPARERS.LESS_THAN_OR_EQUAL_VERSION
		|| comparer === COMPARERS.GREATER_THAN_VERSION || comparer === COMPARERS.GREATER_THAN_OR_EQUAL_VERSION) {
		return _versionCompare(comparer, fieldValue, value);
	}

	if (comparer === COMPARERS.REGULAR_EXPRESION) {
		return _regexCompare(fieldValue, value);
	}

	if (comparer === COMPARERS.ENDS_WITH) {
		return _endsWithCompare(fieldValue, value);
	}

	if (comparer === COMPARERS.STARTS_WITH) {
		return _startsWithCompare(fieldValue, value);
	}

	throw new Error(`Unknown comparer '${comparer}' for conditional configuration`);
}

function _equals(fieldValue: unknown, value: unknown) {
	return JSON.stringify(fieldValue) === JSON.stringify(value);
}

function _contains(fieldValue: unknown, value: unknown | Array<unknown>): boolean {
	if (value && Array.isArray(value)) {
		return value.every((val) => _contains(fieldValue, val));
	}

	return (fieldValue as string).indexOf(value as string) !== -1;
}

function _quantitativeCompare(comparer: Comparer, fieldValue: number | unknown, value: unknown) {
	if (typeof fieldValue !== 'number') {
		fieldValue = parseFloat(fieldValue as string);
	}

	if (isNaN(fieldValue as number)) {
		return false;
	}

	switch (comparer) {
		case COMPARERS.LESS_THAN:
			return fieldValue < value;

		case COMPARERS.LESS_THAN_OR_EQUAL:
			return fieldValue <= value;

		case COMPARERS.GREATER_THAN:
			return fieldValue > value;

		case COMPARERS.GREATER_THAN_OR_EQUAL:
			return fieldValue >= value;
	}
}

function _versionCompare(comparer: Comparer, inputValue: unknown, conditionValue: unknown) {
	if (inputValue == null || conditionValue == null) {
		return false;
	}

	const semVerInputValue = semverUtil.getFromString(inputValue),
		semVerConditionValue = semverUtil.getFromString(conditionValue);

	if (semVerInputValue == null || semVerConditionValue == null) {
		return false;
	}

	const comparisonValue = semverUtil.compare(semVerConditionValue, semVerInputValue);

	switch (comparer) {
		case COMPARERS.LESS_THAN_VERSION:
			return comparisonValue < 0;

		case COMPARERS.LESS_THAN_OR_EQUAL_VERSION:
			return comparisonValue <= 0;

		case COMPARERS.GREATER_THAN_VERSION:
			return comparisonValue > 0;

		case COMPARERS.GREATER_THAN_OR_EQUAL_VERSION:
			return comparisonValue >= 0;
	}
}

function _regexCompare(fieldValue: unknown, value: unknown): boolean {
	if (fieldValue && Array.isArray(fieldValue)) {
		return fieldValue.every((val) => _regexCompare(val, value));
	}

	const regex = new RegExp(value as string);

	return regex.test(fieldValue as string);
}

function _startsWithCompare(fieldValue: unknown, value: unknown) {
	if (fieldValue && Array.isArray(fieldValue)) {
		if (fieldValue.length > 0) {
			return fieldValue[0] === value;
		}

		return false;
	}

	return (fieldValue as string).startsWith(value as string);
}

function _endsWithCompare(fieldValue: unknown, value: unknown) {
	if (fieldValue && Array.isArray(fieldValue)) {
		if (fieldValue.length > 0) {
			return fieldValue[fieldValue.length - 1] === value;
		}

		return false;
	}

	return (fieldValue as string).endsWith(value as string);
}

function _dateCompare(comparer: Comparer, fieldValue: unknown, value: unknown) {
	const
		// @ts-ignore
		fieldDate: Date = new Date(fieldValue),
		// @ts-ignore
		valueDate = new Date(value);

	if (isNaN(fieldDate.valueOf()) || isNaN(valueDate.valueOf())) {
		return false;
	}

	switch (comparer) {
		case COMPARERS.BEFORE:
			return fieldDate < valueDate;

		case COMPARERS.AFTER:
			return fieldDate > valueDate;
	}
}
