import Settings from '../domain/Settings';
import {getSlot} from './slotManager';
import {getCurrentBreakpoint} from './breakpoints';
import pubsub from '../utils/pubsub';
import {isObject} from "../utils/object-util";
import Slot from "../domain/Slot";
import {getAuthKey} from "./messaging";

type HeavyAdLog = {
	profile: string,
	creativeId: string,
	slotName: string,
	placement: string
};

export type HeavyAdData = {
	creativeId: string,
	slot: string,
}

let profile: string;

export function init() {
	const settings = Settings.getInstance();

	if (!settings.features?.heavyAdTracking || typeof window.ReportingObserver === 'undefined')
		return;

	profile = settings.debugInfo?.profile;

	pubsub.subscribe('slot.rendered', injectReporter);
	pubsub.subscribe('heavyAdReporting', sendLogs);
}

function sendLogs(heavyAdData: HeavyAdData) {
	const placement = getSlot(heavyAdData?.slot)?.placement,
		heavyAdLog: HeavyAdLog = {
			'profile': profile ?? window.location.hostname,
			'creativeId': heavyAdData?.creativeId,
			'slotName': heavyAdData?.slot,
			'placement': isObject(placement) ? placement[getCurrentBreakpoint()] : placement
		};

	navigator.sendBeacon(
		`https://advertising-heavy-ad-tracking.target.dpgmedia.cloud/heavy-ad-intervention`,
		JSON.stringify(heavyAdLog)
	);
}

function injectReporter(slot: Slot) {
	const tag = window.apntag?.getTag(slot.domId);

	if (!tag) {
		console.warn('[ADVERT] Heavy Ad Tracking could not find the AST tag for the slot', slot);
		return;
	}

	const frameId = tag?.utiframeId;

	if (!frameId) {
		return;
	}

	const iframe = document.getElementById(frameId) as HTMLIFrameElement;

	if (!iframe) {
		console.warn('[ADVERT] Heavy Ad Tracking could not detect the iframe', frameId, 'for slot', slot);
		return;
	}

	try {
		const script = document.createElement('script');
		script.innerHTML = `
		(new ReportingObserver(() => {
			window.top.postMessage(JSON.stringify({
				"authKey": "${getAuthKey()}",
				"type": "advertCallback",
				"action": "heavyAdReporting",
				"options": {
					"slot": "${slot.name}",
					"creativeId": "${slot.adResponse?.creativeId}"
				}
			}), "*");
		}, { buffered: true })).observe();
		`;
		iframe.contentDocument.body.appendChild(script);
	} catch (e) {
		console.warn('[ADVERT] Heavy Ad Tracking could not inject the reporter', e);
	}
}

