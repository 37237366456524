import {hasPurposesConsent, hasVendorsConsent} from "../services/privacyService";

export type Resource = {
	type: ResourceType,
	content?: string,
	consent?: {
		purposes?: Array<number | string>,
		vendors?: Array<number | string>,
		fallback?: string
	}
};

export enum ResourceType {
	Script = 'script',
	InlineScript = 'inline-script',
	Style = 'style',
	InlineStyle = 'inline-style',
}

type ResourceBuilders = {
	[key in ResourceType]: (content: string) => HTMLElement
};

const RESOURCE_BUILDERS: ResourceBuilders = {
	[ResourceType.Script]: getScript,
	[ResourceType.InlineScript]: getInlineScript,
	[ResourceType.Style]: getStyle,
	[ResourceType.InlineStyle]: getInlineStyle,
};

export function loadResource(resource: Resource) {
	if (typeof RESOURCE_BUILDERS[resource.type] !== 'function') {
		console.warn('[ADVERT] Tried to load an unknown resource type', resource);
		return;
	}

	const consented = hasConsent(resource);

	// No consent and no fallback, so we don't load the resource
	if (!consented && !resource.consent?.fallback) {
		return;
	}

	const content = consented ? resource.content : resource.consent.fallback;

	injectResource(resource.type, content);
}

function injectResource(type: ResourceType, content: string) {
	const id = `advert-resource-${simpleHash(content)}`;

	// Already injected
	if (document.getElementById(id)) {
		return;
	}

	const element = RESOURCE_BUILDERS[type](content);

	element.id = id;

	document.head.appendChild(element);
}

function getStyle(content: string) {
	const linkElement = document.createElement('link');

	linkElement.type = 'text/css';
	linkElement.rel = 'stylesheet';
	linkElement.href = content;

	return linkElement;
}

function getInlineStyle(content: string) {
	const linkElement = document.createElement('style');

	linkElement.innerHTML = content;

	return linkElement;
}

function getScript(content: string) {
	const scriptElement = document.createElement('script');

	scriptElement.type = 'text/javascript';
	scriptElement.src = content;

	return scriptElement;
}

function getInlineScript(content: string) {
	const scriptElement = document.createElement('script');

	scriptElement.type = 'text/javascript';
	scriptElement.innerHTML = content;

	return scriptElement;
}

function hasConsent(resource: Resource): boolean {
	if (!resource.consent) {
		return true;
	}

	const {purposes, vendors} = resource.consent;

	return hasPurposesConsent(purposes ?? []) && hasVendorsConsent(vendors ?? []);
}

function simpleHash(str: string) {
	let hash = 0;
	for (let i = 0; i < str.length; i++) {
		const char = str.charCodeAt(i);
		hash = (hash << 5) - hash + char;
	}
	return (hash >>> 0).toString(36).padStart(7, '0');
}
