import {getCurrentBreakpoint} from '../services/breakpoints';
import bidderHandlers from './bidders/index';

const NO_BIDDERS = [];

export function getBidders(config, slot) {
	const slotInfo = config.slots[slot.name];

	if (typeof slotInfo === 'undefined' || typeof slotInfo.bidders === 'undefined') {
		return NO_BIDDERS;
	}

	return _getBiddersForCurrentBreakpoint(slotInfo)
		.map((bidder) => _getBidder(config, slot, bidder))
		.filter(b => b !== null);
}

function _getBiddersForCurrentBreakpoint(slotInfo) {
	if (Array.isArray(slotInfo.bidders)) {
		return slotInfo.bidders;
	}

	const currentBp = getCurrentBreakpoint();

	if (typeof slotInfo.bidders[currentBp] !== 'undefined') {
		return slotInfo.bidders[currentBp];
	}

	if (typeof slotInfo.bidders.default !== 'undefined') {
		return slotInfo.bidders.default;
	}

	return NO_BIDDERS;
}

function _getBidder(config, slot, slotBidderCfg) {
	if (typeof bidderHandlers[slotBidderCfg.bidder] !== 'undefined') {
		return _getFromBidderHandler(config, slot, slotBidderCfg);
	}

	return bidderHandlers.default(config, slotBidderCfg);
}

function _getFromBidderHandler(config, slot, slotBidderCfg) {
	try {
		return bidderHandlers[slotBidderCfg.bidder](config, slot, slotBidderCfg);
	} catch (e) {
		console.error(`[ADVERT] Something went wrong processing the bidder handler for ${slotBidderCfg.name}`, e);

		return null;
	}
}
