import pubsub from '../utils/pubsub';
import Slot from "./Slot";

function _validateMultiPositionConfig(multiPositionConfig: MultiPositionConfig) {
	if (typeof multiPositionConfig.name !== 'string') {
		throw new Error('\'name\' is required and should be a string in multiPosition config');
	}

	if (!Array.isArray(multiPositionConfig.slots)) {
		throw new Error('\'slots\' is required and should be an array in multiPosition config');
	}
}

function defaultRenderer(node: Node, slotNodes: Array<HTMLElement>) {
	slotNodes.forEach((slotNode) => node.appendChild(slotNode));
}

const COLLAPSE_BEHAVIOURS = {
		'NEVER': 'never',
		'ANY_EMPTY': 'anyEmpty',
		'ALL_EMPTY': 'allEmpty'
	},
	BASE_NAME = 'advert-multi-position';

export default class MultiPosition {
	name: string;
	slots: Array<string>;
	designTemplate?: string;
	renderer: (node: Node, slotNodes: Array<HTMLElement>) => void;
	node: HTMLElement = null;
	slotNodes: HTMLElement[] = [];
	collapseBehaviour: string;
	#slotEmptyStates: Record<string, boolean> = {};
	#onSlotUpdate: (slot: Slot) => void;

	constructor(cfg: MultiPositionConfig) {
		_validateMultiPositionConfig(cfg);

		this.name = cfg.name;
		this.slots = cfg.slots;
		this.designTemplate = cfg.designTemplate;
		this.renderer = cfg.renderer ?? defaultRenderer;
		this.collapseBehaviour = cfg.collapseBehaviour ?? COLLAPSE_BEHAVIOURS.NEVER;

		// Defined here to keep ref for sub/unsub
		this.#onSlotUpdate = (slot: Slot) => {
			this.#slotEmptyStates[slot.name] = slot.isEmpty || !!slot.state.error;
			this.#updateCollapsing();
		};
	}

	setNode(node: HTMLElement) {
		this.node = node;
		this.node.classList.add(BASE_NAME);

		if (this.designTemplate) {
			this.node.setAttribute('template', this.designTemplate);
		}

		this.slotNodes = this.slots.map((slotName) => {
			const slotNode = document.createElement('div');

			slotNode.id = `${BASE_NAME}-${this.name}-${slotName}`;

			return slotNode;
		});

		this.renderer(this.node, this.slotNodes);

		this.#addListeners();
	}

	#addListeners() {
		// Clean up if already added
		this.#removeListeners();

		this.slots.forEach((slot) => {
			pubsub.subscribe(`slot.${slot}.received`, this.#onSlotUpdate);
			pubsub.subscribe(`slot.${slot}.error`, this.#onSlotUpdate);
		});
	}

	#removeListeners() {
		this.slots.forEach((slot) => {
			pubsub.unsubscribe(`slot.${slot}.received`, this.#onSlotUpdate);
			pubsub.unsubscribe(`slot.${slot}.error`, this.#onSlotUpdate);
		});
	}

	#updateCollapsing() {
		switch (this.collapseBehaviour) {
			case COLLAPSE_BEHAVIOURS.ALL_EMPTY: {
				if (this.slots.every(s => this.#slotEmptyStates[s])) {
					this.node.style.display = 'none';
				}

				break;
			}

			case COLLAPSE_BEHAVIOURS.ANY_EMPTY: {
				if (this.slots.some(s => this.#slotEmptyStates[s])) {
					this.node.style.display = 'none';
				}

				break;
			}
		}
	}


	remove() {
		this.#removeListeners();

		if (this.node) {
			this.node.classList.remove(BASE_NAME);
			this.node.innerHTML = '';

			this.node = null;
		}
	}
}
