import Settings from '../domain/Settings';
import * as storage from '../utils/storage';
import {onIdentityUpdate} from "./userService";

type CxenseProperties = {
	id?: string
	expiration?: string
};

const CXENSE_STORAGE_KEY = 'advert-cxense';

export function init() {
	if (!Settings.getInstance().features?.cxense) {
		return;
	}

	const storedCxenseId = getCxenseProperties();

	if (!storedCxenseId?.expiration || new Date(storedCxenseId.expiration) <= new Date()) {
		window.cX = window.cX || {};
		window.cX.callQueue = window.cX.callQueue || [];

		if (Settings.getInstance().cxense?.includeLibrary) {
			_initializeLibrary();
		}

		_storeCxenseId();

		if (Settings.getInstance().cxense?.pageViewEvent) {
			_sendPageViewEvent();
		}
	}
}

function _initializeLibrary() {
	window.cX.options = {consent: true};
	window.cX.callQueue.push(['setConsent', {pv: true}]);
}

function _storeCxenseId() {
	window.cX.callQueue.push(['invoke', () => {
		const updatedCxenseId = window.cX.getCxenseUserId();

		if (updatedCxenseId) {
			storage.put(CXENSE_STORAGE_KEY, JSON.stringify({
				id: updatedCxenseId,
				expiration: _getExpirationDate()
			}));

			// noinspection JSIgnoredPromiseFromCall We don't care about the result
			onIdentityUpdate();
		}
	}]);
}

function _getExpirationDate() {
	const currDate = new Date();

	currDate.setDate(currDate.getDate() + 1);

	return currDate.toISOString();
}

function _sendPageViewEvent() {
	window.cX.callQueue.push(['setEventAttributes', {
		origin: 'dpn-retargeting-Pixel',
		persistedQueryId: '362a54f75d61844e7d6387fed23f72322e15750d'
	}]);
	window.cX.callQueue.push(['setSiteId', '1353733223743909146']);
	window.cX.callQueue.push(['sendPageViewEvent']);
}

export function getCxenseProperties(): CxenseProperties | null {
	const storageValue = storage.get(CXENSE_STORAGE_KEY);

	if (!storageValue) {
		return null;
	}

	try {
		return JSON.parse(storageValue);
	} catch (e) {
		return null;
	}
}
