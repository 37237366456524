import DeepDiff, {Diff} from 'deep-diff';
import {isObject} from "./object-util";

export type Change = {
	kind: string
	path: string[]
	index?: number
	item?: {
		kind: string
		lhs?: unknown
		rhs?: unknown
	}
}

export function applyChange(target: unknown, change: Change) {
	if (change.kind === 'A') {
		const value = _getValueFromPath(target, change.path);

		if (!Array.isArray(value)) {
			return target;
		}

		if (change.item.kind === 'D') {
			const index = value.findIndex((item) => JSON.stringify(item) === JSON.stringify(change.item.lhs));

			if (index !== -1) {
				value.splice(index, 1);
			}
		}

		if (change.item.kind === 'N') {
			const index = change.index > value.length ? value.length : change.index;

			value.splice(index, 0, change.item.rhs);
		}

		return target;
	}

	return DeepDiff.applyChange(target, {}, change as Diff<unknown, unknown>);
}

function _getValueFromPath(base: unknown, path: string[]) {
	for (const field of path) {
		if (!isObject(base)) {
			break;
		}

		base = base[field];
	}

	return base;
}
