import Settings from '../domain/Settings';
import Slot from '../domain/Slot';
import {SlotStateError} from '../domain/SlotStateError';
import {getArrayBatched, getFuncBatched} from '../utils/batch-util';
import * as nodeUtils from '../utils/node';
import * as perf from '../utils/performance';
import pubsub from '../utils/pubsub';
import * as adManager from './adManager';
import breakpoints from './breakpoints';
import lazyLoadService from './lazyLoadService';
import sizeMappings from './sizeMappings';
import {getSlots, getSlotsAndPositions} from './slotManager';

const DEFAULT_SLOT_LOAD_BATCH_COUNT = 6;

let slots: Array<Slot> = [],
	loadSlotBatchCount: Settings['loadSlotBatchCount'] = DEFAULT_SLOT_LOAD_BATCH_COUNT,
	waitingForDefine: Record<Slot['name'], AnyFunction> = {};

_hookEvents();

export async function init() {
	perf.mark('xandr - slotloader.init - start');
	const settings = Settings.getInstance();

	loadSlotBatchCount = settings.loadSlotBatchCount || DEFAULT_SLOT_LOAD_BATCH_COUNT;
	waitingForDefine = {};

	slots.forEach((slot) => _removeSlotListeners(slot));

	slots = getSlots();

	slots.forEach((slot) => _addSlotListeners(slot));

	await defineSlots(slots.filter((slot) => slot.getPreload()));

	perf.mark('xandr - slotloader.init - end');
}

function _addSlotListeners(slot: Slot) {
	adManager.addRenderFinishListener(slot, (adObject: XandrAdObject) => _onRenderFinish(slot, adObject));
	adManager.addAvailableListeners(slot, (adObject: XandrAdObject) => _onAdAvailable(slot, adObject));
	adManager.addRequestedListeners(slot,
		() => _onAdRequestedEvent(slot),
		(error: string, ...params: Array<unknown>) => _onAdRequestFailureEvent(slot, error, ...params));
	adManager.addMediationListeners(slot, (empty: boolean) => _onMediationEvent(slot, empty));
}

function _removeSlotListeners(slot: Slot) {
	adManager.removeRenderFinishListener(slot);
	adManager.removeAvailableListeners(slot);
	adManager.removeMediationListeners(slot);
	adManager.removeRequestedListeners(slot);
}

function _hookEvents() {
	pubsub.subscribe('persisted', _onPersisted);
	pubsub.subscribe('slot.defined', _onSlotDefined);
}

function _xandrErrorToSlotError(xandrError: string) {
	switch (xandrError) {
		case 'adRequestFailure':
			return SlotStateError.REQUEST_FAILURE;

		case 'adBadRequest':
			return SlotStateError.BAD_REQUEST;
	}
}

function _onPersisted() {
	const slotsToRefresh = slots.filter((/** Slot*/ slot) => slot.state.showTagCalled);

	adManager.updatePageTargeting();

	return refreshSlots(slotsToRefresh, 'persisted');
}

function _onRenderFinish(slot: Slot, adObject: XandrAdObject) {
	slot.setAdData(adObject);
	slot.setRendered(true);
}

function _onAdAvailable(slot: Slot, adObject: XandrAdObject) {
	slot.setAdData(adObject);
	slot.setReceived(true);
}

function _onMediationEvent(slot: Slot, empty: boolean) {
	slot.setMediated(true, empty);
}

function _onAdRequestedEvent(slot: Slot) {
	slot.setRequested(true);
}

function _onAdRequestFailureEvent(slot: Slot, error: string, ...params: Array<unknown>) {
	const errorType = _xandrErrorToSlotError(error);

	slot.setError(errorType);

	console.error('[ADVERT] Could not load slot', slot, '| Error type:', errorType, '| Error data:', ...params);
}

function _isUsedOnCurrentBreakpoint(slot: Slot) {
	const sizes = sizeMappings.getSizesFromSizeMapForBreakpoint(slot.sizeMapping, breakpoints.getCurrentBreakpoint());

	return typeof sizes !== 'undefined' && sizes.length > 0;
}

export async function defineSlots(toDefine: Array<Slot>) {
	perf.mark('xandr - slotmanager.define - start');

	const slotsToDefine = toDefine
		.filter(_isUsedOnCurrentBreakpoint);

	slotsToDefine.forEach((slot) => {
		adManager.defineSlot(slot);
		slot.setDefined(true);
	});

	perf.mark('xandr - slotmanager.extendSlots - start');
	await pubsub.publish('extendSlots', slotsToDefine);
	perf.mark('xandr - slotmanager.extendSlots - end');

	_fetchSlots(slotsToDefine);

	perf.mark('xandr - slotmanager.define - end');
}

const defineSlotsBatched = getFuncBatched<[Array<Slot>]>((calls) => {
	return defineSlots(calls.flatMap(c => c[0]));
});

function _fetchSlots(definedSlots: Array<Slot>) {
	const batchCount = getBatchCount(),
		{
			main: defaultMemberIdSlots,
			overridden: memberIdSlots
		} = _sortByMemberIdAndKeywords(definedSlots);

	getArrayBatched(defaultMemberIdSlots, batchCount)
		.map((batch) => adManager.loadSlots(batch));

	memberIdSlots.forEach(({
		slots: slotsGroup,
		keywords
	}) => {
		getArrayBatched(slotsGroup, batchCount).forEach((batchedSlots) => {
			adManager.loadSlotsWithLimitedTargeting(batchedSlots as Slot[], keywords);
		});
	});
}

function getBatchCount(): number {
	let batchCount = loadSlotBatchCount;

	if (typeof batchCount === 'object') {
		batchCount = batchCount[breakpoints.getCurrentBreakpoint()];
	}

	if (typeof batchCount !== 'number') {
		return DEFAULT_SLOT_LOAD_BATCH_COUNT;
	}

	return batchCount;
}

type SlotsByMemberId = {
	main: Slot[];
	overridden: Array<{
		slots: Slot[]
		memberId: number
		keywords: string[]
	}>
};

function _sortByMemberIdAndKeywords(definedSlots: Slot[]): SlotsByMemberId {
	return definedSlots.reduce((agg, slot) => {
		if (!slot.memberId) {
			agg.main.push(slot);
			return agg;
		}

		const slotDifferentSeatKeywords = slot.differentSeatKeywords ?? [],
			matchedGroup = agg.overridden.find(group =>
				group.memberId === slot.memberId
				&& group.keywords.length === slotDifferentSeatKeywords.length
				&& group.keywords.every((keyword) => slotDifferentSeatKeywords.includes(keyword)));

		if (!matchedGroup) {
			agg.overridden.push({
				slots: [slot],
				memberId: slot.memberId,
				keywords: slotDifferentSeatKeywords
			});
		} else {
			matchedGroup.slots.push(slot);
		}

		return agg;
	}, {
		main: [],
		overridden: []
	} as SlotsByMemberId);
}

async function _waitForSlotDefine(slot: Slot) {
	if (slot.state.defined) {
		return;
	}

	// It should define a non-preloaded slot, but no others as those are likely broken or busy.
	if (slot.getPreload()) {
		await new Promise((resolve) => {
			waitingForDefine[slot.name] = resolve;
		});
	} else {
		await defineSlotsBatched([slot]);
	}
}

function _onSlotDefined(slot: Slot) {
	if (typeof waitingForDefine[slot.name] === 'function') {
		waitingForDefine[slot.name]();
		delete waitingForDefine[slot.name];
	}
}

export async function refreshSlots(slotsToRefresh: Array<Slot>, reason?: string) {
	slotsToRefresh.forEach((slot) => {
		slot.targeting.refresh = reason;

		slot.setError(null);
		slot.setMediated(false);
	});

	adManager.prepareRefreshingSlots(slotsToRefresh);

	perf.mark('xandr - slotmanager.extendSlots - refresh - start');
	await pubsub.publish('extendSlots', slotsToRefresh);
	perf.mark('xandr - slotmanager.extendSlots - refresh - end');

	adManager.refreshSlots(slotsToRefresh);
}

export async function loadSlotInNode(slot: Slot) {
	if (!slots.includes(slot)) {
		slots.push(slot);
		_addSlotListeners(slot);
	}

	await _waitForSlotDefine(slot);
	await lazyLoadService.waitForSlot(slot);

	if (!nodeUtils.isConnected(slot.node)) {
		console.warn('[ADVERT] Tried rendering slot in a node that\'s no longer connected to the DOM', slot, slot.node);

		return;
	}

	if (slot.state.showTagCalled) {
		refreshSlots([slot]);
	} else {
		adManager.renderSlot(slot);
		slot.setShowTagCalled(true);
	}

	return slot;
}

export async function loadSlot(platformName: string, nodeId: string | HTMLElement = platformName): Promise<Array<Slot>> {
	const positions = await getSlotsAndPositions(platformName, nodeId),
		loadedSlots = await Promise.all(
			positions.map((slot) => loadSlotInNode(slot))
		);

	return loadedSlots.filter(s => s);
}
