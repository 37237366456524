import {getConsentManagementConfig} from './consentManagement';
import {extendPriceFloorConfig} from './priceFloors';
import {applyPlaceholders} from '../../utils/placeholder';
import * as storage from '../../utils/storage';
import {getRandomizedUserId, getUserId} from "../../services/userService";
import {extendDSAModuleConfig} from "./dsaModule";

export function getPlaceholders(): Record<string, string> {
	return {
		'PWID': storage.getFromCookie('authId'),
		'domain': window.location.hostname,
		'pageUrl': `${window.location.origin}${window.location.pathname}`,
		'userId': getUserId(),
		'randomizedUserId': getRandomizedUserId()
	};
}

export function getModuleConfig(config: PrebidConfig) {
	const prebidModuleConfig: AnyObject = Object.assign(
		JSON.parse(JSON.stringify(config.additionalConfig ?? {})),
		getConsentManagementConfig(config),
	);

	extendPriceFloorConfig(prebidModuleConfig);
	extendDSAModuleConfig(prebidModuleConfig);

	return applyPlaceholders(prebidModuleConfig, getPlaceholders());
}

type BidderConfig = {
	bidders: Array<string>
	config: AnyObject
};

export function getBidderModuleConfigs(config: PrebidConfig) {
	const additionalBidderConfigs = JSON.parse(JSON.stringify(config.additionalBidderConfigs ?? [])),
		placeholders = getPlaceholders();

	return additionalBidderConfigs.map((bidderConfig: BidderConfig) => {
		return applyPlaceholders(bidderConfig, placeholders);
	});
}

export function getBidderSettingsConfigs(config: PrebidConfig) {
	const bidderSettings = JSON.parse(JSON.stringify(config.bidderSettings ?? {})),
		placeholders = getPlaceholders();

	return applyPlaceholders(bidderSettings, placeholders);
}


export default {
	getPlaceholders,
	getModuleConfig,
	getBidderModuleConfigs,
	getBidderSettingsConfigs
};
