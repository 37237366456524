import Settings from '../domain/Settings';
import pubsub from '../utils/pubsub';
import screenUtil from '../utils/screen';

let breakpoints,
	currentBreakpoint;

_setEventListeners();

export function init() {
	const settings = Settings.getInstance();

	_validateBreakpoints(settings.breakpoints);

	breakpoints = JSON.parse(JSON.stringify(settings.breakpoints));
	currentBreakpoint = calculateCurrentBreakpoint();
}

function _setEventListeners() {
	pubsub.subscribe('resize', function() {
		if (typeof breakpoints === 'undefined') {
			return;
		}

		const previousBreakpoint = currentBreakpoint;

		currentBreakpoint = calculateCurrentBreakpoint();

		if (previousBreakpoint !== currentBreakpoint) {
			pubsub.publish('breakpoint.changed', currentBreakpoint);
		}
	});
}

function _validateBreakpoints(bps) {
	const values = [];

	Object.keys(bps).forEach((bp) => {
		if (typeof bps[bp] !== 'number' || bps[bp] < 0) {
			throw new Error(`Invalid breakpoint '${bp}'. '${bps[bp]}' is not a number or less than zero.`);
		}

		if (values.indexOf(bps[bp]) !== -1) {
			throw new Error(`Invalid breakpoint '${bp}'. Breakpoint at '${bps[bp]}' already exists.`);
		}

		values.push(bps[bp]);
	});

	if (Math.min(...values) > 0) {
		bps['0'] = 0;
	}
}

function calculateCurrentBreakpoint() {
	const screenWidth = screenUtil.getSize().width;

	return Object.keys(breakpoints).reduce((agg, bp) => {
		if (breakpoints[bp] <= screenWidth) {
			if (agg === null || breakpoints[bp] > breakpoints[agg]) {
				agg = bp;
			}
		}

		return agg;
	}, null);
}

export function getCurrentBreakpoint() {
	return currentBreakpoint;
}

export function getBreakpoints() {
	return breakpoints;
}

export default {
	init,
	getCurrentBreakpoint,
	getBreakpoints
};
