import DeepDiff, {Diff} from 'deep-diff';
import {isConditionActive} from '../utils/condition-util';
import {clone} from '../utils/object-util';
import * as storage from '../utils/storage';
import {getActiveExperiments} from './experimentService';
import {isUserLoggedIn} from './userService';

const FIELD_TYPES = {
		STORAGE: 'storage:',
		STORAGE_ARRAY: 'storage[]:',
		COOKIE: 'cookie:',
		SESSION_STORAGE: 'session:',
		FRACTION: 'fraction',
		EXPERIMENT: 'experiment:',
		LOGGED_IN: 'loggedIn',
		URL: 'url',
		DOCUMENT_WIDTH: 'documentWidth',
		DOCUMENT_HEIGHT: 'documentHeight',
		VIEWPORT_WIDTH: 'viewportWidth',
		VIEWPORT_HEIGHT: 'viewportHeight',
	},
	RANDOM_VALUE = Math.random();


type ConfigWithConditionals = Record<string, unknown> & { conditionalConfigurations?: Array<ConditionalConfig> };

export function applyConditionalConfiguration(config: ConfigWithConditionals) {
	if (typeof config !== 'object' || config === null || typeof config.conditionalConfigurations === 'undefined') {
		return config;
	}

	const activeConditionalConfigs = config.conditionalConfigurations.filter((conCfg, index) => {
		const isActiveConditional = conCfg.conditions.some((conditionGroup) =>
			conditionGroup.every(({field, ...condition}) => {
				try {
					return isConditionActive(condition as Condition, getValueForField(field as string));
				} catch (e) {
					console.error('[ADVERT] Something went wrong calculating conditional configurations:', e);
					config.conditionalConfigurations.splice(index, 1);

					return false;
				}
			}));

		if (isActiveConditional) {
			config.conditionalConfigurations[index].active = true;
		}

		return isActiveConditional;
	});

	return activeConditionalConfigs.reduce((cfg, conCfg) => {
		return _applyConditional(cfg, conCfg);
	}, clone(config));
}

function _applyConditional(applyTo: AnyObject, conditional: ConditionalConfig) {
	conditional.config.forEach((change) => {
		DeepDiff.applyChange(applyTo, {}, change as Diff<unknown, unknown>);
	});

	return applyTo;
}

export function getValueForField(field: string) {
	if (field.startsWith(FIELD_TYPES.STORAGE)) {
		return storage.get(field.slice(FIELD_TYPES.STORAGE.length));
	}

	if (field.startsWith(FIELD_TYPES.STORAGE_ARRAY)) {
		return storage.getArray(field.slice(FIELD_TYPES.STORAGE_ARRAY.length));
	}

	if (field.startsWith(FIELD_TYPES.SESSION_STORAGE)) {
		return storage.getFromSession(field.slice(FIELD_TYPES.SESSION_STORAGE.length));
	}

	if (field.startsWith(FIELD_TYPES.COOKIE)) {
		return storage.getFromCookie(field.slice(FIELD_TYPES.COOKIE.length));
	}

	if (field === FIELD_TYPES.FRACTION) {
		return RANDOM_VALUE;
	}

	if (field.startsWith(FIELD_TYPES.EXPERIMENT)) {
		return _getExperimentGroup(field);
	}

	if (field === FIELD_TYPES.LOGGED_IN) {
		return isUserLoggedIn();
	}

	if (field === FIELD_TYPES.URL) {
		return window.location.href;
	}

	if (field === FIELD_TYPES.DOCUMENT_WIDTH) {
		return document.body.clientWidth;
	}

	if (field === FIELD_TYPES.DOCUMENT_HEIGHT) {
		return document.body.clientHeight;
	}

	if (field === FIELD_TYPES.VIEWPORT_WIDTH) {
		return document.documentElement.clientWidth;
	}

	if (field === FIELD_TYPES.VIEWPORT_HEIGHT) {
		return document.documentElement.clientHeight;
	}

	throw new Error('Unknown field');
}

function _getExperimentGroup(field: string) {
	const experimentName = field.slice(FIELD_TYPES.EXPERIMENT.length),
		experiments = getActiveExperiments();

	return experiments.find((experiment) => experiment.name === experimentName)?.group ?? null;
}
