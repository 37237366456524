import adhese from './adhese';
import appnexus from './appnexus';
import def from './default';
import improvedigital from './improvedigital';
import rubicon from './rubicon';

export default {
	'default': def,
	rubicon,
	improvedigital,
	'imprweborama': improvedigital,
	appnexus,
	'gps': appnexus,
	'plista': appnexus,
	'groupm': appnexus,
	'adhese': adhese,
};
