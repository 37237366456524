import {run as runHooks} from './hooks';

const topics: Record<string, AnyFunction[]> = {};

export function subscribe(topic: string | string[], callback: AnyFunction) {
	if (typeof callback !== 'function') {
		console.warn('[ADVERT] pubsub.subscribe - callback is not of type function.');

		return;
	}

	if (typeof topic === 'object' && topic instanceof Array) {
		for (let i = 0; i < topic.length; i++) {
			_subscribeTopic(topic[i], callback);
		}
	} else {
		_subscribeTopic(topic, callback);
	}
}

function _subscribeTopic(topic: string, callback: AnyFunction) {
	if (typeof topic !== 'string') {
		console.warn('[ADVERT] pubsub.subscribe - topic is not of type string.');

		return;
	}

	if (!Object.prototype.hasOwnProperty.call(topics, topic)) {
		topics[topic] = [];
	}

	topics[topic].push(callback);
}

export async function publish(topic: string, ...args: unknown[]) {
	runHooks(topic, ...args);

	if (!Object.prototype.hasOwnProperty.call(topics, topic)) {
		return;
	}

	await Promise.all(topics[topic].map(async (cb) => {
		try {
			await cb(...args);
		} catch (e) {
			console.error(`[ADVERT] Something went wrong calling publishing ${topic}`, e);
		}
	}));
}

export function unsubscribe(topic: string, callback?: AnyFunction) {
	if (!Object.prototype.hasOwnProperty.call(topics, topic)) {
		return;
	}

	if (typeof callback === 'undefined') {
		topics[topic] = [];
	} else {
		_unsubscribeSingleCallback(topic, callback);
	}
}

function _unsubscribeSingleCallback(topic: string, callback: AnyFunction) {
	const index = topics[topic].indexOf(callback);

	if (index === -1) {
		return;
	}

	topics[topic].splice(index, 1);
}

export function listSubscriptions(topic?: string) {
	return typeof topic === 'undefined' ? Object.assign({}, topics) : Array.isArray(topics[topic]) ? topics[topic].slice() : [];
}

export default {
	subscribe,
	publish,
	unsubscribe,
	listSubscriptions
};
