import * as adManager from '../../services/adManager';
import * as perf from '../../utils/performance';

interface Window {
	pbjs?: {
		getUserIds: () => Record<string, string>
	};
}

export async function setPrebidUserIds() {
	perf.mark('prebid - getUserIds - start');

	const pbjsUserIds = (window as Window).pbjs.getUserIds(),
		userIds = [];

	if (pbjsUserIds.criteoId) {
		userIds.push({
			'type': 'criteo',
			'id': pbjsUserIds.criteoId
		});
	}

	if (pbjsUserIds.tdid) {
		userIds.push({
			'type': 'ttd',
			'id': pbjsUserIds.tdid
		});
	}

	if (pbjsUserIds.identityLink) {
		userIds.push({
			'type': 'liveramp',
			'id': pbjsUserIds.identityLink
		});
	}

	perf.mark('prebid - getUserIds - end');

	await adManager.setUserPageOptions(userIds);
}
