import MultiPosition from '../domain/MultiPosition';
import Settings from '../domain/Settings';
import Slot, {MoveBehaviour} from '../domain/Slot';
import {SlotStateError} from '../domain/SlotStateError';
import * as perf from '../utils/performance';
import breakpoints from './breakpoints';

let slots: Array<Slot> = [],
	multiPositions: Array<MultiPosition> = [];

export async function init() {
	perf.mark('config - slotmanager.init - start');
	const settings = Settings.getInstance();

	_removeSlots();
	settings.slots.forEach((s) => _createSlot(s));
	_createMultiPositions(settings.multiPositions);

	perf.mark('config - slotmanager.init - end');
}

function _createSlot(slotCfg: SlotConfig) {
	try {
		slots.push(new Slot(slotCfg));
	} catch (e) {
		console.error('[ADVERT] Could not create slot. Error:', e);
	}
}

function _createMultiPositions(settingMultiPositions: Array<MultiPositionConfig>) {
	multiPositions = settingMultiPositions?.map((mp) => new MultiPosition(mp)) ?? [];
}

function _removeSlots() {
	slots.forEach((slot) => {
		slot.removeNode();
	});

	multiPositions.forEach((multiPosition) => {
		multiPosition.remove();
	});

	slots = [];
	multiPositions = [];
}

export function getSlotNameFromMapping(platformName: string): string | null {
	const slotNameMappings = Settings.getInstance().slotNameMappings;

	if (!slotNameMappings) {
		return platformName;
	}

	const mapping = slotNameMappings[platformName];

	if (typeof mapping === 'undefined') {
		throw new Error(`[ADVERT] Unknown slot '${platformName}'`);
	}

	if (typeof mapping === 'string' || mapping === null) {
		return mapping as string | null;
	}

	const currentBp = breakpoints.getCurrentBreakpoint();

	// Can be null to not render
	return typeof mapping[currentBp] === 'undefined' ? mapping.default : mapping[currentBp];
}

export async function getSlotsAndPositions(platformName: string, nodeId: string | HTMLElement = platformName): Promise<Array<Slot>> {
	const slotName = getSlotNameFromMapping(platformName);

	// Intentionally unmapped slot, not an error
	if (slotName === null) {
		return [];
	}

	return (await _getSlotsAndPositions(slotName, nodeId)).filter(s => s);
}

async function _getSlotsAndPositions(name: string, node: string | HTMLElement): Promise<Array<Slot>> {
	const slot = slots.find((s) => s.name === name);

	if (!slot) {
		const multiPosition = multiPositions.find((mp) => mp.name === name);

		if (!multiPosition) {
			throw new Error(`No slot found for ${name}`);
		}

		return _loadMultiPosition(multiPosition, node);
	}

	return [await _getSlotAndPosition(name, node)];
}

async function _getSlotAndPosition(name: string, node?: string | HTMLElement): Promise<Slot | null> {
	const slot = slots.find((s) => s.name === name);

	if (!slot) {
		throw new Error(`No slot found for ${name}`);
	}

	const nodeElement: HTMLElement = typeof node === 'string' ? document.getElementById(node) : node;

	if (!nodeElement) {
		// Slot already loaded for this node
		if (slot.node && slot.node.getAttribute('data-advert-orig-id') === node) {
			return null;
		}

		slot.setError(SlotStateError.NODE_NOT_FOUND);

		throw new Error(`No DOM element found for id ${node}`);
	}

	if (slot.node === nodeElement || slot.copies?.some((s) => s.node === nodeElement)) {
		return null;
	}

	// Moving slot to a new node
	if (slot.node && slot.moveBehaviour === MoveBehaviour.COPY) {
		const slotCfg = Settings.getInstance().slots.find((s) => s.name === slot.name),
			newSlot = new Slot({
				...slotCfg,
				name: `${slot.name}_${slot.copies.length + 1}`,
				preload: false // Can't exactly be preloaded can it? This also makes it define itself later on
			});

		// TODO
		// _addSlotListeners(newSlot);
		slot.copies.push(newSlot);
		slots.push(newSlot);

		newSlot.updateNode(nodeElement);

		return newSlot;
	}

	slot.updateNode(nodeElement);

	return slot;
}

async function _loadMultiPosition(multiPosition: MultiPosition, node: string | HTMLElement): Promise<Array<Slot | null>> {
	const nodeElement: HTMLElement = typeof node === 'string' ? document.getElementById(node) : node;

	if (!nodeElement) {
		throw new Error(`No DOM element found for id ${node}`);
	}

	if (multiPosition.node === nodeElement) {
		return [];
	}

	multiPosition.setNode(nodeElement);

	return Promise.all(multiPosition.slots.map((slot, index) => {
		return _getSlotAndPosition(slot, multiPosition.slotNodes[index].id);
	}));
}

export function getSlot(slotName: string) {
	return slots.find((s) => s.name === slotName);
}

export function getSlots() {
	return [...slots];
}

export function getMultiPositions() {
	return [...multiPositions];
}
