export function get(key: string): string | null {
	return window.localStorage?.getItem(key) ?? null;
}

export function getArray(key: string): Array<string> {
	const arr = get(key);

	return arr ? decodeURIComponent(arr).split(',') : [];
}

export function getFromCookie(key: string): string | null {
	if (!window.navigator.cookieEnabled) {
		return null;
	}

	const cookieArr = document.cookie.match('(?:^|;\\s*)' + key + '=([^;]*)');

	return cookieArr?.[1] ?? null;
}

export function put(key: string, item: string): void {
	window.localStorage?.setItem(key, item);
}

export function getFromSession(key: string): string | null {
	return window.sessionStorage?.getItem(key) ?? null;
}
